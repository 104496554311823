/*eslint-disable */
module.exports = {
  version: {
    major: 1,
    patch: 0,
    buildCounter: envVars.BUILD_COUNTER,
    gitCommit: envVars.GIT_COMMIT,
  },
  authorization: {
    tenant: '1211f5b7-bd34-4621-8eef-3ebbcb827794',
    azureAuthorizeUrl:
      'https://login.microsoftonline.com/1211f5b7-bd34-4621-8eef-3ebbcb827794/oauth2/authorize?api-version=1.0',
    azureTokenUrl:
      'https://login.microsoftonline.com/1211f5b7-bd34-4621-8eef-3ebbcb827794/oauth2/token?api-version=1.0',
    azureClientId: '4ce630fc-f815-46a4-a94d-3fa248253b14',
    resource: '00000002-0000-0000-c000-000000000000',
  },
  endpoints: {
    vmsClientUrl: 'https://staging.vms.mi9cdn.com',
    vmsApiUrl: 'https://staging.vms-api.mi9cdn.com',
  },
  build: {
    optimize: true,
  },
  pusher: {
    appKey: '6b6d57aa791994adc0c1',
    cluster: 'ap1',
    channel: 'vms',
    event: 'vms',
  },
  brightcove: {
    playerUrl: `//players.brightcove.net/4460760524001/B195POvL_default/index.min.js`,
    liveEventPlayOutUrl: 'https://9nowlivehls-i.akamaihd.net/hls/live/253526/[referenceId]/master.m3u8',
  },
  imageFactory: {
    url: 'https://images.9vms.com.au',
    liveEvent: {
      prefix: 'le',
      liveImageKey: 'live_event_image_live_hd',
      upcomingImageKey: 'live_event_image_upcoming_dark',
    },
  },
  uiTheme: 'green-theme',
  websocket: {
    endpoint: 'wss://8369hto7rj.execute-api.ap-southeast-2.amazonaws.com/stag/',
    channel: 'vms',
  },
  bugPreviewUrlPrefix: 'https://s3.ap-southeast-2.amazonaws.com/content-api-stag.vms.mi9cdn.com/',
  featureFlags: {
    usingPusher: false,
    nineVideoFeaturesEnabled: true,
    showDeviceDrmToggle: true,
    showDeviceFullHdToggle: true,
    showDeviceStartOverToggle: true,
    allowChannelActiveStreamToggle: false,
    showDeviceTokenisationToggle: true,
    showDeviceManifestFilteringToggle: true,
    showSortingByCtvPosition: false,
    showSortingByPosition: false,
    liveEventListContextNavigation: {
      showCSVImportButton: false,
    },
  },
};
