import moment from 'moment';
import { RESET_FORM, SHOW_FORM_VALIDATION } from 'src/scripts/actions/form';
import {
  GET_SEASON_AND_ASSOCIATED_DATA_SUCCEEDED,
  GET_SEASON_AND_ASSOCIATED_DATA_FAILED,
  SEASON_UPDATED,
  SAVE_SEASON_FAILED,
  SAVE_SEASON_STARTED,
  SAVE_SEASON_SUCCEEDED,
} from 'src/scripts/actions/season';
import { GENDER_GROUPS, CONTENT_GROUPS } from 'src/scripts/lib/videoAdMetadataGroups';
import { getValidationErrors, getValidationErrorMessage } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/seasonForm';
import classificationCodes from '../constants/classificationCodes';

export const seasonClassificationCodes = classificationCodes.filter(({ code }) => code !== 'X');

export const initialState = {
  season: {
    episodesGeoBlocked: true,
    clipsGeoBlocked: true,
    episodesWatermarkEnabled: true,
    clipsWatermarkEnabled: true,
    genderSkew: GENDER_GROUPS[0].id,
    contentGroup: CONTENT_GROUPS[0].id,
    seoTvSeries: null,
    seoPageTitle: null,
    seoDescription: null,
    isBoxSet: false,
    defaultAdsEnabled: true,
    publishedDate: null,
    seriesPageLiveRailEnabled: true,
    seriesPageEpisodesRailEnabled: true,
    seriesPageClipsRailEnabled: true,
    seriesPageLiveRailDisplayName: null,
    seriesPageEpisodesRailDisplayName: null,
    seriesPageClipsRailDisplayName: null,
    classifications: [
      {
        code: '',
        type: 'Longtail',
        digitalExclusive: false,
        incrementalSpend: false,
        startDate: undefined,
        endDate: null,
      },
    ],
    producerNotes: null,
  },
  channels: [],
  validationErrors: {},
  showValidationErrors: false,
  errorMessage: null,
  saving: false,
  savedSeason: null,
  initialFormDataLoaded: false,
  defaultContentIdChannel: null,
  defaultContentIdUsagePolicy: null,
  defaultContentIdMatchPolicy: null,
  contentIdOwnershipStrategy: null,
  contentIdOwnershipCountries: [],
  allCatalogCodes: [],
};

function preprocessSeason(season) {
  if (!season) {
    return initialState.season;
  }
  if (!season.availableContentSeries) {
    season.availableContentSeries = [];
  }
  return season;
}

function hasExistingClassifications(classifications) {
  return classifications && classifications[0] && classifications[0].startDate;
}

function getDefaultClassificationWithStartDateNow() {
  return [
    {
      ...initialState.season.classifications[0],
      startDate: moment().format(),
    },
  ];
}

function getSeasonAndAssociatedData(state, actionData) {
  const preprocessedSeason = preprocessSeason(actionData.season);
  if (hasExistingClassifications(preprocessedSeason.classifications)) {
    return {
      ...state,
      ...actionData,
      season: preprocessedSeason,
      initialFormDataLoaded: true,
    };
  }
  return {
    ...state,
    ...actionData,
    season: {
      ...preprocessedSeason,
      classifications: getDefaultClassificationWithStartDateNow(),
    },
    initialFormDataLoaded: true,
  };
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SEASON_AND_ASSOCIATED_DATA_SUCCEEDED:
      return getSeasonAndAssociatedData(state, action.data);

    case GET_SEASON_AND_ASSOCIATED_DATA_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };

    case SEASON_UPDATED:
      if (state.showValidationErrors) {
        const validationErrors = getValidationErrors(
          { ...state.season, ...action.season },
          getValidationRules(state.season)
        );
        const errorMessage = getValidationErrorMessage(validationErrors);
        return {
          ...state,
          season: {
            ...state.season,
            ...action.season,
          },
          validationErrors,
          errorMessage,
        };
      }
      return {
        ...state,
        season: {
          ...state.season,
          ...action.season,
        },
      };

    case SAVE_SEASON_STARTED:
      return { ...state, saving: true };

    case SAVE_SEASON_FAILED:
      return { ...state, errorMessage: action.error, saving: false };

    case SAVE_SEASON_SUCCEEDED:
      return { ...state, saving: false, savedSeason: action.data.season };

    case SHOW_FORM_VALIDATION:
      const validationErrors = getValidationErrors(state.season, getValidationRules(state.season));
      const errorMessage = getValidationErrorMessage(validationErrors);
      return {
        ...state,
        showValidationErrors: true,
        validationErrors,
        errorMessage,
      };

    case RESET_FORM:
      return { ...initialState };

    default:
  }

  return state;
}
