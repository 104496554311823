import _ from 'lodash';
import {
  combinationRequired,
  isCurrentEventStartDateTimeValid,
  isCurrentEventEndDateTimeValid,
  isUpNextEventStartDateTimeValid,
  isUpNextEventEndDateTimeValid,
  required,
  stringEndsWithAnySpecifiedString,
  dateFormat,
  isAfter,
  notBefore,
  notBeforeToday,
  isHttpOrHttpsURL,
  isHttpsURL,
  mustMatchRegex,
} from 'src/scripts/lib/formValidation/generalValidators';
import { REMOTE_EVENT, BROADCAST_EVENT } from 'src/scripts/lib/liveEventTypes';
import { SCHEDULED_EVENT, INSTANT_EVENT } from 'src/scripts/lib/liveEventScheduleTypes';
import { TV, NETWORK } from 'src/scripts/lib/libraries';
import { notEmptyArray } from '../generalValidators';

export const notBeforePromoStartDate = () =>
  notBefore({ field: 'promoStartDate', label: 'Promo Start Date & Time' });
export const isAfterAvailabilityStartDate = () =>
  isAfter({ field: 'availabilityStartDate', label: 'Availability Start Date & Time' });
export const notBeforeAvailabilityEndDate = () =>
  notBefore({ field: 'availabilityEndDate', label: 'Availability End Date & Time' });
export const endsWithValidFileExtension = () => stringEndsWithAnySpecifiedString(['.m3u8', '.mpd']);
export const isValidURL = (library) => (library === TV ? isHttpsURL() : isHttpOrHttpsURL());

const externalIdLengthRegex = /^.{1,15}$/;
const baseRules = (library) => {
  const rules = [
    { field: 'name', label: 'Live Event Name', customValidators: [required()] },
    { field: 'logoId', label: 'Logo', customValidators: [required()] },
    { field: 'imageId', label: 'Image', customValidators: [required()] },
    {
      field: 'externalId',
      label: 'External ID',
      customErrorMessage: 'External Id must be between 1 and 15 characters',
      customValidators: mustMatchRegex(externalIdLengthRegex),
    },
    /**
     * fastChannel is required when isFastChannel is true
     */
    {
      field: 'isFastChannel',
      label: 'Is FAST Channel',
      customValidators: combinationRequired([{ field: 'fastChannel', label: 'FAST Channel' }]),
    },
  ];
  if (library === TV) {
    rules.push({ field: 'liveEventGroupId', label: 'Live Event Group', customValidators: [required()] });
    /**
     * SeasonId is required when tvSeriesId exists
     */
    rules.push({
      field: 'seasonId',
      label: 'Season',
      customValidators: combinationRequired([{ field: 'tvSeriesId', label: 'TV Series' }]),
    });
  }
  if (library === NETWORK) {
    rules.push({ field: 'position', label: 'Position', customValidators: [required()] });
  }
  return rules;
};

const baseRemoteEventRules = (library, ssaiEnabled) => {
  const rules = [
    {
      field: 'remoteURL',
      label: 'Remote Asset URL',
      customValidators: [required(), endsWithValidFileExtension(), isValidURL(library)],
    },
  ];
  if (library === TV) {
    if (ssaiEnabled) {
      rules.push({
        field: 'ssaiRemoteURL',
        label: 'SSAI Remote Asset URL',
        customValidators: [required(), endsWithValidFileExtension(), isValidURL(library)],
      });
    }
    rules.push({ field: 'oztamPublisherId', label: 'Oztam Publisher ID', customValidators: [required()] });
  }
  return rules;
};

const baseBroadcastEventRules = (library) => {
  const rules = [];
  if (library === TV) {
    rules.push({
      field: 'catalogCodes',
      label: 'Catalog Codes',
      customValidators: [required(), notEmptyArray()],
    });
  }
  return rules;
};

const scheduledEventRules = [
  { field: 'promoStartDate', label: 'Promo Start Date & Time', customValidators: [required(), dateFormat()] },
  {
    field: 'availabilityStartDate',
    label: 'Availability Start Date & Time',
    customValidators: [required(), dateFormat(), notBeforeToday(), notBeforePromoStartDate()],
  },
  {
    field: 'availabilityEndDate',
    label: 'Availability End Date & Time',
    customValidators: [required(), dateFormat(), isAfterAvailabilityStartDate()],
  },
];
const currentEventStartAndEndDateTimeRules = [
  {
    field: 'programStartTime',
    customValidators: [isCurrentEventStartDateTimeValid()],
  },
  {
    field: 'programEndTime',
    customValidators: [isCurrentEventEndDateTimeValid()],
  },
  {
    field: 'upNextEvent.programStartTime',
    customValidators: [isUpNextEventStartDateTimeValid()],
  },
  {
    field: 'upNextEvent.programEndTime',
    customValidators: [isUpNextEventEndDateTimeValid()],
  },
];

const compileValidationRules = (eventType, scheduleType, library, ssaiEnabled) => {
  const validationRuleSets = {
    [REMOTE_EVENT]: {
      [SCHEDULED_EVENT]: [
        ...baseRules(library),
        ...baseRemoteEventRules(library, ssaiEnabled),
        ...scheduledEventRules,
        ...currentEventStartAndEndDateTimeRules,
      ],
      [INSTANT_EVENT]: [
        ...baseRules(library),
        ...baseRemoteEventRules(library, ssaiEnabled),
        ...currentEventStartAndEndDateTimeRules,
      ],
    },
    [BROADCAST_EVENT]: {
      [SCHEDULED_EVENT]: [
        ...baseRules(library),
        ...baseBroadcastEventRules(library, ssaiEnabled),
        ...scheduledEventRules,
        ...currentEventStartAndEndDateTimeRules,
      ],
      [INSTANT_EVENT]: [
        ...baseRules(library),
        ...baseBroadcastEventRules(library, ssaiEnabled),
        ...currentEventStartAndEndDateTimeRules,
      ],
    },
  };
  return validationRuleSets[eventType][scheduleType];
};

const removeValidationsOnUnchangedFields = (
  validationRules,
  initialLiveEventState,
  liveEventStateToValidate
) => {
  return _.filter(validationRules, (rule) => {
    const fieldName = rule.field;
    // Always valiate the oztamPublisherId field. This is to prevent issues with existing live events
    // that don't already have an oztamPublisherId. Specifically, when editing an existing remote event
    // to enable ssai, it would create the event in brightcove then fail model validation on the API, leading to an incosistent state
    // and not being able to enable ssai.
    if (fieldName === 'oztamPublisherId') return true;

    /**
     * The validation on season depends on the existence of tvSeriesId (season is required if tvSeriesId exists).
     * The dependency makes it more complicated to determine whether the season rule should be included
     * than to simply included it anyway.
     */
    if (fieldName === 'seasonId') return true;

    if (fieldName === 'position') return true;

    return liveEventStateToValidate[fieldName] !== initialLiveEventState[fieldName];
  });
};

const getValidationRules = (initialLiveEventState, liveEventStateToValidate) => {
  const eventTypeValidationRules = compileValidationRules(
    liveEventStateToValidate.eventType,
    liveEventStateToValidate.scheduleType,
    liveEventStateToValidate.library,
    liveEventStateToValidate.ssaiEnabled
  );

  const isEdit = !!initialLiveEventState.id;
  if (isEdit) {
    return removeValidationsOnUnchangedFields(
      eventTypeValidationRules,
      initialLiveEventState,
      liveEventStateToValidate
    );
  }

  return eventTypeValidationRules;
};

export default getValidationRules;
