import {
  required,
  dateFormat,
  isAfter,
  notBeforeToday,
  isPositiveNumber,
  isBetween,
  combinationRequired,
} from 'src/scripts/lib/formValidation/generalValidators';
import { INT_RANGE } from '../constants';

const contentIdCombinationRequired = combinationRequired([
  { field: 'contentIdChannel', label: 'Content ID Channel' },
  { field: 'contentIdUsagePolicy', label: 'Content ID Usage Policy' },
  { field: 'contentIdMatchPolicy', label: 'Content ID Match Policy' },
]);

export const isAfterStartDate = () =>
  isAfter({ field: 'availability', label: 'Start Date & Time' }, ['expiry']);

export const mustSelectDropdownOption = () => (rule, formState) =>
  formState[rule.field] === 0 ? `${rule.label} requires a selection` : null;

const getValidationRules = (state) => {
  return [
    { field: 'tvSeriesId', label: 'TV Series', customValidators: [required(), mustSelectDropdownOption()] },
    { field: 'seasonId', label: 'Season', customValidators: [required(), mustSelectDropdownOption()] },
    {
      field: 'number',
      label: 'Episode Number',
      customValidators: [required(), isPositiveNumber(), isBetween(0, INT_RANGE.max)],
    },
    { field: 'availability', label: 'Start Date & Time', customValidators: [required(), dateFormat()] },
    { field: 'broadcastAirDate', label: 'Broadcast Air Date', customValidators: [dateFormat()] },
    {
      field: 'expiry',
      label: 'End Date & Time',
      customValidators: [
        dateFormat(),
        isAfterStartDate(),
        state !== 'ARCHIVED' ? notBeforeToday(['expiry']) : null,
      ].filter((x) => x !== null),
    },
    {
      field: 'contentIdChannel',
      label: 'Content ID Channel',
      customValidators: contentIdCombinationRequired,
    },
    {
      field: 'contentIdUsagePolicy',
      label: 'Content ID Usage Policy',
      customValidators: contentIdCombinationRequired,
    },
    {
      field: 'contentIdMatchPolicy',
      label: 'Content ID Match Policy',
      customValidators: contentIdCombinationRequired,
    },
  ];
};

export default getValidationRules;
