import _ from 'lodash';
import { RESET_FORM, SHOW_FORM_VALIDATION } from 'src/scripts/actions/form';
import {
  GET_LIVE_EVENT_FORM_INITIAL_DATA_SUCCEEDED,
  GET_LIVE_EVENT_FAILED,
  LIVE_EVENT_UPDATED,
  SAVE_LIVE_EVENT_STARTED,
  SAVE_LIVE_EVENT_SUCCEEDED,
  SAVE_LIVE_EVENT_FAILED,
  LIVE_EVENT_GET_GENRES_FAILED,
} from 'src/scripts/actions/liveEvent';
import { GET_SEASONS_SUCCEEDED } from 'src/scripts/actions/season';
import { TV, NETWORK } from 'src/scripts/lib/libraries';
import { getValidationErrors, getValidationErrorMessage } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/liveEventForm';
import {
  shallowFormatValidDatesToISOString,
  shallowSetEmptyDateFieldsToNull,
} from 'src/scripts/reducers/helpers';
import { REMOTE_EVENT } from 'src/scripts/lib/liveEventTypes';
import { SCHEDULED_EVENT } from 'src/scripts/lib/liveEventScheduleTypes';
import {
  TV_LIVE_EVENT_VIDEO_GROUPS,
  NETWORK_LIVE_EVENT_VIDEO_GROUPS,
} from 'src/scripts/lib/liveEventVideoGroups';

export const liveEventDefaults = [
  {
    library: TV,
    videoGroups: TV_LIVE_EVENT_VIDEO_GROUPS,
    defaultVideoGroup: 'ShortformEntertainment',
    defaultEventType: REMOTE_EVENT,
    defaultPreroll: false,
  },
  {
    library: NETWORK,
    videoGroups: NETWORK_LIVE_EVENT_VIDEO_GROUPS,
    defaultVideoGroup: 'NetworkNewsAndCurrentAffairs',
    defaultEventType: REMOTE_EVENT,
    defaultPreroll: true,
  },
];

export const initialState = {
  liveEvent: {
    eventType: REMOTE_EVENT,
    scheduleType: SCHEDULED_EVENT,
    subtitle: '',
    description: '',
    genreId: null,
    genreName: null,
    classificationCode: '',
    programStartTime: null,
    programEndTime: null,
    upNextEvent: {
      name: '',
      subtitle: '',
      description: '',
      genreId: null,
      genreName: null,
      classificationCode: '',
      programStartTime: null,
      programEndTime: null,
    },
    ssaiEnabled: false,
    oztamPublisherId: null,
    isSports: false,
    producerNotes: null,
  },
  initialLiveEventState: {},
  showValidationErrors: false,
  validationErrors: {},
  errorMessage: null,
  externalId: null,
  saving: false,
  seasons: [],
  videoGroups: [],
  genres: [],
  initialFormDataLoaded: false,
  isFetchError: false,
};

function getLibraryDefaultLists(library) {
  const eventDefaults = _.find(liveEventDefaults, { library });
  return { videoGroups: eventDefaults.videoGroups };
}

function processInitialLiveEvent(data) {
  if (data.liveEvent) {
    return {
      ...initialState.liveEvent,
      upNextEvent: { ...initialState.liveEvent.upNextEvent },
      ...data.liveEvent,
    };
  }
  const eventDefaults = _.find(liveEventDefaults, { library: data.library });
  return {
    ...initialState.liveEvent,
    upNextEvent: { ...initialState.liveEvent.upNextEvent },
    eventType: eventDefaults.defaultEventType,
    videoGroupId: eventDefaults.defaultVideoGroup,
    preRoll: eventDefaults.defaultPreroll,
  };
}

function processFormInitialData(data) {
  const liveEvent = processInitialLiveEvent(data);
  let seasons = data.seasons || initialState.seasons;
  if (liveEvent.partOfSeries) {
    liveEvent.tvSeriesId = liveEvent.partOfSeries.id;
    if (!seasons.length) {
      seasons = [liveEvent.partOfSeason];
    }
  }
  const defaultLists = getLibraryDefaultLists(data.library);
  return { liveEvent, seasons, ...defaultLists, genres: data.genres };
}

const preprocessLiveEventDateFields = (liveEvent, validationErrorsObject) => {
  const liveEventDateFields = ['promoStartDate', 'availabilityStartDate', 'availabilityEndDate'];
  let updatedLiveEvent = shallowFormatValidDatesToISOString(
    liveEvent,
    liveEventDateFields,
    validationErrorsObject
  );
  updatedLiveEvent = shallowSetEmptyDateFieldsToNull(updatedLiveEvent, liveEventDateFields);
  return updatedLiveEvent;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LIVE_EVENT_FORM_INITIAL_DATA_SUCCEEDED:
      const eventDefaults = _.find(liveEventDefaults, { library: action.data.library });
      return {
        ...state,
        ...processFormInitialData(action.data),
        initialFormDataLoaded: true,
        initialLiveEventState: {
          ...initialState.liveEvent,
          eventType: eventDefaults.defaultEventType,
          ...action.data.liveEvent,
        },
      };

    case GET_LIVE_EVENT_FAILED:
      return {
        ...state,
        errorMessage: action.error,
      };

    case LIVE_EVENT_UPDATED:
      const incomingLiveEvent = { ...state.liveEvent, ...action.liveEvent };
      const validationErrorsObject = getValidationErrors(
        incomingLiveEvent,
        getValidationRules(state.initialLiveEventState, incomingLiveEvent)
      );
      const updatedIncomingLiveEvent = preprocessLiveEventDateFields(
        incomingLiveEvent,
        validationErrorsObject
      );

      if (state.showValidationErrors) {
        const errorMessage = getValidationErrorMessage(validationErrorsObject);
        return {
          ...state,
          liveEvent: updatedIncomingLiveEvent,
          validationErrors: validationErrorsObject,
          errorMessage,
        };
      }
      return {
        ...state,
        liveEvent: updatedIncomingLiveEvent,
      };

    case SAVE_LIVE_EVENT_STARTED:
      return { ...state, saving: true };

    case SAVE_LIVE_EVENT_FAILED:
      return { ...state, errorMessage: action.error, saving: false };

    case SAVE_LIVE_EVENT_SUCCEEDED:
      return { ...state, errorMessage: null, saving: false };

    case GET_SEASONS_SUCCEEDED:
      return {
        ...state,
        seasons: action.data.seasons,
      };

    case SHOW_FORM_VALIDATION:
      const validationErrors = getValidationErrors(
        state.liveEvent,
        getValidationRules(state.initialLiveEventState, state.liveEvent)
      );
      const errorMessage = getValidationErrorMessage(validationErrors);
      return {
        ...state,
        showValidationErrors: true,
        validationErrors,
        errorMessage,
      };

    case LIVE_EVENT_GET_GENRES_FAILED:
      return { ...state, errorMessage: action.error, savedGenre: null, isFetchError: true };
    case RESET_FORM:
      return { ...initialState };

    default:
  }

  return state;
}
