import { getStreams, patchStream } from './apiWrappers/vmsApi/admin';

export const STREAM_TYPES = {
  hls: 'hls',
  dash: 'dash',
};

export function listStreams(params) {
  return getStreams(params);
}

export function updateStream(streamId, info) {
  return patchStream(streamId, info);
}
