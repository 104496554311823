import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Q from 'q';
import _ from 'lodash';
import cuid from 'cuid';
import Dropzone from 'react-dropzone';
import { ProgressBar, Input } from 'react-bootstrap';
import { uploadImage, clearUploadedImage } from 'src/scripts/actions/imageUpload';
import { updateImage } from 'src/scripts/actions/multiImageUpload';
import { getImage, validateAndDetermineImageSize } from 'src/scripts/lib/keyArtFileUtility';
import {
  getPossibleImageTypes,
  getSizeForImageType,
  determineImageType,
  getExistingImageRefId,
  parseImageIdFromImageRef,
  getSizesForImageTypes,
} from 'src/scripts/lib/processImage';
import { isDefaultImage } from 'src/scripts/lib/util';
import { formatStringToRemoveUnderscore } from 'src/scripts/lib/stringFormatter';

const isImageRemoved = false;
export class MultiImageUpload extends React.Component {
  constructor() {
    super();
    this.state = {
      images: [],
      validationMessages: [],
    };
  }

  componentWillUnmount() {
    this.state.images.forEach((image) => URL.revokeObjectURL(image.preview));
  }

  onDrop(files) {
    const getImagePromises = files.map((file) => getImage(file));
    const allPromises = Q.all(getImagePromises);
    const images = this.state.images;
    const validationMessages = [];

    if (!this.validateImageLimit()) {
      return;
    }

    allPromises.then((result) => {
      result.forEach((image, index) => {
        const imageFile = files[index];
        this.validateAndUploadImage(imageFile, image, images, validationMessages);
      });

      this.setState({
        images,
        validationMessages,
      });
    });
  }

  validateImageLimit() {
    // Images in image state do not get removed when removeImage() is called (only visually, and they are flagged), hence the query selector.
    if (this.props.limit && document.querySelectorAll('.image-container').length >= this.props.limit) {
      this.setState({
        validationMessages: [`Image limit exceeded. Limit: ${this.props.limit}`],
      });

      return false;
    }

    return true;
  }

  setImageType = (event, imageRefId) => {
    const imageType = !(event.target.value === 'Select Image Type') ? event.target.value : null;
    const existingImageId = parseImageIdFromImageRef(imageRefId);
    if (existingImageId) {
      this.props.updateImage(imageRefId, { type: imageType, imageId: existingImageId });
    } else {
      this.props.updateImage(imageRefId, { type: imageType });
    }
  };

  removeImage = (event) => {
    const imageRefId = event.target.id;
    const existingImageId = parseImageIdFromImageRef(imageRefId);
    if (existingImageId) {
      this.props.updateImage(imageRefId, { imageId: existingImageId, isImageRemoved: true });
    } else {
      this.props.updateImage(imageRefId, { isImageRemoved: true });
    }
    const imageToBeDeleted = document.getElementById(imageRefId);
    imageToBeDeleted.parentNode.removeChild(imageToBeDeleted);
  };

  validateAndUploadImage(imageFile, image, listOfImages, validationMessages) {
    try {
      const acceptedImageSizes = getSizesForImageTypes(this.props.imageTypes);
      const imageSize = validateAndDetermineImageSize(image, acceptedImageSizes);
      const imageRef = cuid();
      let imageItem = {
        imageRef,
        preview: URL.createObjectURL(imageFile),
        width: image.width,
        height: image.height,
        size: imageSize,
      };

      const type = determineImageType(imageSize, this.props.imageTypes);
      imageItem = { ...imageItem, type };
      listOfImages.push(imageItem);

      this.props.updateImage(imageRef, { type, isImageRemoved });
      this.props.uploadImage(this.props.resourceGroup, imageFile, imageRef, 'MULTI_FILE_UPLOAD');
    } catch (err) {
      validationMessages.push(`Invalid Image \'${imageFile.name}\': ${err.message} `);
    }
  }

  renderSelectImageTypeInput = (size, imageRef, existingImageType) => {
    const possibleImageType = getPossibleImageTypes(size, this.props.imageTypes);
    if (possibleImageType.length === 1) {
      return (
        <Input type="select" className="image-types-input-group" disabled value={possibleImageType[0]}>
          <option value="0">{formatStringToRemoveUnderscore(possibleImageType[0])}</option>
        </Input>
      );
    }
    if (possibleImageType.length > 1) {
      const currentType = this.props.multiImageUpload[imageRef]
        ? this.props.multiImageUpload[imageRef].type
        : existingImageType;
      return (
        <Input
          type="select"
          ref="imageTypeInputGroup"
          className="image-types-input-group"
          id="select-image"
          onChange={(e) => this.setImageType(e, imageRef)}
          value={currentType}
          data-pw="select-image-type"
        >
          <option value="0">Select Image Type</option>
          {possibleImageType.map((imageType) => {
            return (
              <option key={imageType} value={imageType}>
                {formatStringToRemoveUnderscore(imageType)}
              </option>
            );
          })}
        </Input>
      );
    }
    return null;
  };

  renderAccceptedImageTypes = (imageTypes) => {
    if (!imageTypes) return null;
    const types = [];
    imageTypes.forEach((type) => {
      const message = ` ${_.capitalize(type)}(${getSizeForImageType(type).name})`;
      types.push(message);
    });
    return <p className="supported-images">{`Supported image types: ${types}.`}</p>;
  };

  renderErrorBox = () => {
    const { validationMessages } = this.state;
    if (!validationMessages.length) return null;
    const errorBox = validationMessages.map((errorMessage) => <p>{errorMessage}</p>);
    return <div className="alert alert-danger">{errorBox}</div>;
  };

  render() {
    const { images } = this.state;
    const existingImages = this.props.existingImages;
    let existingImagesPreview;
    const uploadedImages = images.map((image) => (
      <div className="preview-container" id={image.imageRef}>
        <button type="button" className="close" id={image.imageRef} onClick={this.removeImage}>
          &times;
        </button>
        <div className="image-container">
          <div className={`thumb-inner ${image.size.cssClassName}`}>
            <img className="image" src={image.preview} />
          </div>
        </div>
        {this.props.multiImageUpload[image.imageRef] && (
          <ProgressBar
            now={this.props.multiImageUpload[image.imageRef].uploadPercentage}
            label={`${this.props.multiImageUpload[image.imageRef].uploadPercentage}%`}
          />
        )}
        {this.renderSelectImageTypeInput(image.size, image.imageRef)}
      </div>
    ));

    if (existingImages) {
      existingImagesPreview = existingImages.map((existingImage) => {
        if (isDefaultImage(existingImage)) {
          return null;
        }
        return (
          <div>
            <div className="preview-container" id={getExistingImageRefId(existingImage)}>
              <button
                type="button"
                className="close"
                id={getExistingImageRefId(existingImage)}
                onClick={this.removeImage}
              >
                &times;
              </button>
              <div className="image-container">
                {existingImage.isImageTemp && <div className="temporary-image">TEMPORARY</div>}
                <div className={`thumb-inner ${existingImage.type}`}>
                  <img
                    className="image"
                    src={
                      existingImage.resizedImageUrls && existingImage.resizedImageUrls.w272
                        ? existingImage.resizedImageUrls.w272
                        : existingImage.url
                    }
                  />
                </div>
              </div>
              {this.renderSelectImageTypeInput(
                getSizeForImageType(existingImage.type),
                getExistingImageRefId(existingImage),
                existingImage.type
              )}
            </div>
          </div>
        );
      });
    }

    return (
      <section className="multi-image-upload" data-pw="multi-image-upload">
        <Dropzone accept=".png,.jpg,.jpeg" onDrop={this.onDrop.bind(this)}>
          {({ getRootProps, getInputProps }) => (
            <div className="base-style" {...getRootProps()} onClick={(evt) => evt.preventDefault()}>
              <p className="select-images">
                <input {...getInputProps()} data-pw="select-images" /> Or Drop images here
              </p>
            </div>
          )}
        </Dropzone>
        {this.renderAccceptedImageTypes(this.props.imageTypes)}
        <aside className="thumbs-container">{uploadedImages}</aside>
        <div>
          <aside className="thumbs-container">{existingImagesPreview}</aside>
        </div>
        {this.renderErrorBox()}
      </section>
    );
  }
}

MultiImageUpload.propTypes = {
  resourceGroup: PropTypes.string,
  imageUploadRef: PropTypes.string,
  uploadImage: PropTypes.func,
  limit: PropTypes.number,
  clearUploadedImage: PropTypes.func,
  updateImage: PropTypes.func,
  multiImageUpload: PropTypes.object,
  existingImages: PropTypes.array,
  imageTypes: PropTypes.array,
};

export const mapStateToProps = (state) => {
  return {
    multiImageUpload: state.multiImageUpload,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    uploadImage: (resourceGroup, selectedImageFile, imageUploadRef, imageUploadType) =>
      dispatch(uploadImage(resourceGroup, selectedImageFile, imageUploadRef, imageUploadType)),
    clearUploadedImage: (imageUploadRef) => dispatch(clearUploadedImage(imageUploadRef)),
    updateImage: (imageUploadRef, imageData) => dispatch(updateImage(imageUploadRef, imageData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiImageUpload);
