import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Label, Button } from 'react-bootstrap';
import environment from 'config/environment';
import ItemList from 'src/scripts/components/ItemList/ItemList';
import * as pagination from 'src/scripts/lib/pagination';
import ItemListHeader from 'src/scripts/components/ItemList/ItemListHeader';
import ContextNavigation from 'src/scripts/components/ContextNavigation';
import ModalItem from 'src/scripts/components/ModalItem';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import LiveEventFormLoader from 'src/scripts/components/LiveEvent/LiveEventFormLoader';
import {
  deleteLiveEventAndCloseConfirmation,
  getLiveEvents,
  updateLiveEventStatuses,
} from 'src/scripts/actions/liveEvent';
import { isDeleteEnabled, isEditEnabled } from 'src/scripts/lib/liveEventWorkflowActionsProvider';
import { TV } from 'src/scripts/lib/libraries';
import { IMAGE_NOT_FOUND_URL } from 'src/scripts/constants/common';
import { isRemoteEvent } from 'src/scripts/lib/liveEventTypes';
import * as liveEventsVmsApi from '../../actions/apiWrappers/vmsApi/common/liveEvents';
import { liveEventStatus } from '../../lib/liveEventStatus';
import NotesWithTooltip from '../NotesWithTooltip';
import CsvImportLiveEvents from './CsvImportLiveEvents';

export function displayStatus(liveEventItem) {
  if (liveEventItem.status === 'Error') {
    return <Label bsStyle={'danger'}>{liveEventItem.status}</Label>;
  }

  if (
    (liveEventItem.status === 'Streaming' || liveEventItem.status === 'Promoting') &&
    liveEventItem.preview
  ) {
    return (
      <div>
        {liveEventItem.status}
        <br />
        <Label>Only on Staging</Label>
      </div>
    );
  }

  return liveEventItem.status;
}

export class LiveEventList extends React.Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.state = {
      oztamPublishers: [],
      isCsvImportLiveEventModalOpen: false,
    };
    this.fetchOztamPublishers();
  }

  componentDidMount() {
    this.statusUpdateTimer = setInterval(() => {
      this.props.updateLiveEventStatuses();
    }, 10000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.savedLiveEvent || this.props.page !== prevProps.page) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    clearInterval(this.statusUpdateTimer);
    this.statusUpdateTimer = undefined;
  }

  getAllowedActions(liveEvent) {
    const actions = [];
    if (isEditEnabled(liveEvent)) {
      actions.push('edit');
    }
    if (isDeleteEnabled(liveEvent)) {
      actions.push('delete');
    }
    return actions;
  }

  loadData(liveEvent = {}) {
    return this.props.getLiveEvents(this.props.params.library, {
      offset:
        liveEvent.offset !== null && liveEvent.offset !== undefined
          ? liveEvent.offset
          : pagination.calculateOffset(this.props.page),
      sort: liveEvent.sort || this.props.sort,
      ...(liveEvent.filters || this.props.filters),
    });
  }

  isInTVLibrary() {
    return this.props.params.library.toUpperCase() === TV;
  }

  renderHideStrapItemListHeader = () =>
    !this.isInTVLibrary() && (
      <ItemListHeader
        label="Hide Strap"
        dataField="hideStrap"
        dataFormat={(liveEventItem) => (liveEventItem.hideStrap ? 'Yes' : 'No')}
      />
    );

  renderEventGroupHeader = () =>
    this.isInTVLibrary() && (
      <ItemListHeader
        dataField="liveEventGroup.name"
        dataFormat={(liveEventItem) =>
          liveEventItem.liveEventGroup ? liveEventItem.liveEventGroup.name : null
        }
        filter
        label="Event Group"
      />
    );

  renderOztamPublisherNameItemListHeader = () =>
    this.isInTVLibrary() && (
      <ItemListHeader
        label="Oztam Publisher Name"
        dataField="oztamPublisherName"
        dataFormat={(liveEventItem) => {
          return liveEventItem.oztamPublisherName;
        }}
      />
    );

  renderExternalIdHeader = () =>
    this.isInTVLibrary() && (
      <ItemListHeader
        label="External ID"
        dataField="externalId"
        dataType="string"
        filter
        dataFormat={(liveEventItem) => {
          return liveEventItem.externalId;
        }}
      />
    );

  renderPositionListHeader = () =>
    !this.isInTVLibrary() && (
      <ItemListHeader label="Position" dataFormat={(liveEventItem) => liveEventItem.position} />
    );

  fetchOztamPublishers = async () => {
    const oztamPublishers = await liveEventsVmsApi.getOztamPublisherIdsForTvEvents();
    const formattedOztamPublishers = oztamPublishers.map((oztamPublisher) => ({
      name: oztamPublisher.oztamChannelName,
      id: oztamPublisher.id,
    }));
    this.setState({ oztamPublishers: formattedOztamPublishers });
  };

  getLiveEventsWithOztamPublisherNames = (liveEvents) => {
    const liveEventsWithOztamPublisherNames = liveEvents.map((liveEvent) => {
      const oztamPublisher = this.state.oztamPublishers.find(
        (publisher) => publisher.id === liveEvent.oztamPublisherId
      );
      liveEvent.oztamPublisherName = oztamPublisher ? oztamPublisher.name : null;

      return liveEvent;
    });

    return liveEventsWithOztamPublisherNames;
  };

  actionList = (liveEventItem) => {
    const actions = this.getAllowedActions(liveEventItem);
    if (!actions.length) return '';
    return (
      <NavDropdownMenu
        key={liveEventItem.id}
        component="div"
        buttonClass="btn btn-default"
        title={<i className="fa fa-ellipsis-v"></i>}
        className="pull-left"
        id={`live-event-item-button-${liveEventItem.id}`}
      >
        {actions.indexOf('edit') > -1 ? (
          <ModalItem
            title="Edit Live Event"
            form={<LiveEventFormLoader liveEventId={liveEventItem.id} library={liveEventItem.library} />}
          />
        ) : (
          ''
        )}
        {actions.indexOf('delete') > -1 ? (
          <ModalItem
            title="Delete Live Event"
            form={
              <ConfirmationDialog
                invitation={`Clicking Delete will permanently delete "${liveEventItem.name}" live event. Are you sure you wish to proceed?`}
                ok={this.props.deleteLiveEventAndCloseConfirmation.bind(
                  this,
                  this.props.params.library,
                  liveEventItem.id
                )}
                buttonClass="danger"
                buttonLabel="Delete"
              />
            }
          />
        ) : (
          ''
        )}
      </NavDropdownMenu>
    );
  };

  toggleImportCSVLiveEventsModal = (isModelOpen = undefined) => {
    this.setState((prevState) => ({
      isCsvImportLiveEventModalOpen: isModelOpen || !prevState.isCsvImportLiveEventModalOpen,
    }));
  };

  render() {
    const liveEventList = {
      pageData:
        this.state.oztamPublishers.length > 0
          ? this.getLiveEventsWithOztamPublisherNames(this.props.liveEvents)
          : this.props.liveEvents,
      totalCount: this.props.count,
      pagination: {
        pageSize: pagination.pageSize,
        activePage: this.props.page,
      },
    };

    const breadcrumbs = [`${this.props.params.library.toUpperCase()} Live Events`];
    const csvBulkImportButton = environment.featureFlags.liveEventListContextNavigation.showCSVImportButton &&
      this.isInTVLibrary() && (
        <Button
          className={'export-btn btn btn-sm btn-default csv-import-button'}
          type="button"
          ref="okButton"
          onClick={this.toggleImportCSVLiveEventsModal.bind(this)}
        >
          CSV Bulk Event Import
        </Button>
      );

    return (
      <div>
        <ContextNavigation breadcrumbList={breadcrumbs} rightComponentList={[csvBulkImportButton]} />
        <ItemList
          key={this.props.params.library}
          id="list-live-event"
          list={liveEventList}
          getItems={this.loadData}
          defaultSort="-updatedAt"
          filters={this.props.filters}
        >
          <ItemListHeader
            label="Thumbnail"
            dataField="image"
            dataFormat={(liveEventItem) => {
              return (
                <span className="row-image-span">
                  <img
                    ref="img"
                    className="row-image"
                    src={(liveEventItem.image && liveEventItem.image.url) || IMAGE_NOT_FOUND_URL}
                    alt={(liveEventItem.image && liveEventItem.image.alt) || ''}
                  />
                </span>
              );
            }}
          />
          <ItemListHeader sort label="Date Updated" dataField="updatedAt" dataType="date" />
          <ItemListHeader
            label="Source"
            dataField="source"
            dataFormat={(liveEventItem) => {
              return isRemoteEvent(liveEventItem) ? 'Remote' : 'Broadcast';
            }}
          />
          {this.renderOztamPublisherNameItemListHeader()}
          {this.renderExternalIdHeader()}
          {this.renderPositionListHeader()}
          <ItemListHeader
            dataField="name"
            dataFormat={(liveEventItem) => {
              return (
                <a href={`/#/live/${liveEventItem.library.toLowerCase()}/events/${liveEventItem.id}`}>
                  {liveEventItem.name}
                </a>
              );
            }}
            filter
            label="Event Name"
            sort
          />
          {this.renderEventGroupHeader()}
          <ItemListHeader sort label="Promo Start Date" dataField="promoStartDate" dataType="date" />
          <ItemListHeader
            sort
            label="Availability Start Date"
            dataField="availabilityStartDate"
            dataType="date"
          />
          <ItemListHeader
            sort
            label="Availability End Date"
            dataField="availabilityEndDate"
            dataType="date"
          />
          <ItemListHeader
            label="Notes"
            dataFormat={(liveEventItem) => {
              return (
                <div className="notes-tooltip">
                  <NotesWithTooltip notes={liveEventItem.producerNotes} emptyText="No Notes for this Event" />
                </div>
              );
            }}
          />
          {this.renderHideStrapItemListHeader()}
          <ItemListHeader
            label="Status"
            dataField="status"
            dataFormat={displayStatus}
            filter={{ type: 'dropdown', options: Object.keys(liveEventStatus) }}
          />
          <ItemListHeader label="Actions" dataFormat={(liveEventItem) => this.actionList(liveEventItem)} />
        </ItemList>
        {this.state.isCsvImportLiveEventModalOpen && (
          <CsvImportLiveEvents onCancel={() => this.toggleImportCSVLiveEventsModal(false)} />
        )}
      </div>
    );
  }
}

LiveEventList.propTypes = {
  params: PropTypes.object.isRequired,
  liveEvents: PropTypes.array,
  count: PropTypes.number,
  page: PropTypes.number,
  offset: PropTypes.number,
  getLiveEvents: PropTypes.func,
  savedLiveEvent: PropTypes.object,
  sort: PropTypes.string,
  filters: PropTypes.object,
  deleteLiveEventAndCloseConfirmation: PropTypes.func,
  updateLiveEventStatuses: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    liveEvents: state.liveEvents.liveEvents,
    savedLiveEvent: state.liveEvents.savedLiveEvent,
    offset: state.liveEvents.offset,
    count: state.liveEvents.count,
    sort: state.itemList.sort,
    filters: state.itemList.filters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLiveEvents: (library, params) => dispatch(getLiveEvents(library, params)),
    deleteLiveEventAndCloseConfirmation: (library, liveEventId) =>
      dispatch(deleteLiveEventAndCloseConfirmation(library, liveEventId)),
    updateLiveEventStatuses: () => dispatch(updateLiveEventStatuses()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveEventList);
