import { required, dateFormat, combinationRequired } from 'src/scripts/lib/formValidation/generalValidators';

const defaultContentIdCombinationRequired = combinationRequired([
  { field: 'defaultContentIdChannel', label: 'Content ID Channel' },
  { field: 'defaultContentIdUsagePolicy', label: 'Content ID Usage Policy' },
  { field: 'defaultContentIdMatchPolicy', label: 'Content ID Match Policy' },
]);

export const ownershipCountriesValidator = (rule, formState) => {
  if (
    formState.contentIdOwnershipStrategy === 'inclusion' ||
    formState.contentIdOwnershipStrategy === 'exclusion'
  ) {
    if (!formState.contentIdOwnershipCountries || !formState.contentIdOwnershipCountries.length) {
      return 'At least one Content ID Ownership country must be provided.';
    }
  }
  return null;
};

const validationRules = [
  { field: 'tvSeriesId', label: 'TV Series', customValidators: required() },
  { field: 'name', label: 'Season Name', customValidators: required() },
  { field: 'description', label: 'Season Description', customValidators: required() },
  { field: 'seasonNumber', label: 'Season Number', customValidators: required() },
  { field: 'publishedDate', label: 'Published Date', customValidators: dateFormat() },
  {
    field: 'defaultContentIdChannel',
    label: 'Content ID Channel',
    customValidators: defaultContentIdCombinationRequired,
  },
  {
    field: 'defaultContentIdUsagePolicy',
    label: 'Content ID Usage Policy',
    customValidators: defaultContentIdCombinationRequired,
  },
  {
    field: 'defaultContentIdMatchPolicy',
    label: 'Content ID Match Policy',
    customValidators: defaultContentIdCombinationRequired,
  },
  {
    field: 'contentIdOwnershipCountries',
    label: 'Ownership Countries',
    customValidators: ownershipCountriesValidator,
  },
];

const getValidationRules = () => validationRules;
export default getValidationRules;
