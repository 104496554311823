import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ItemList from '../ItemList/ItemList';
import ItemListHeader, { FILTER_TYPES } from '../ItemList/ItemListHeader';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import ModalItem from 'src/scripts/components/ModalItem';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import ErrorMessageAlert from '../ErrorMessageAlert';
import SuccessMessageAlert from '../SuccessMessageAlert';
import ExportStreamsCatalog from './internal/ExportStreamsCatalog';
import { ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { resetItemList } from 'src/scripts/actions/itemList';
import { ClearFiltersButton } from 'src/scripts/components/ItemList/buttons/ClearFiltersButton';

export class StreamView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMarkets: null,
      selectedChannels: null,
      selectedStreamTypes: null,
    };

    this.getStreams = this.getStreams.bind(this);
    this.clearFiltersSelection = this.clearFiltersSelection.bind(this);
  }

  getStreams(params = {}) {
    this.props.listStreams(params.filters);
  }

  onMarketSelectionChange(markets) {
    this.setState({
      selectedMarkets: markets,
    });
  }

  onChannelSelectionChange(channels) {
    this.setState({
      selectedChannels: channels,
    });
  }

  onStreamTypeSelectionChange(streamTypes) {
    this.setState({
      selectedStreamTypes: streamTypes,
    });
  }

  clearFiltersSelection() {
    this.state.selectedChannels = null;
    this.state.selectedMarkets = null;
    this.state.selectedStreamTypes = null;

    return this.props.resetItemList();
  }

  renderBreadcrumb = () => (
    <ol className={'mi9-breadcrumb breadcrumb'}>
      <li>{'Streams'}</li>
    </ol>
  );

  renderUrl = () => <ItemListHeader label={'URL'} dataField={'url'} />;

  renderMarketSlug = () => (
    <ItemListHeader
      label={'Market'}
      dataField={'marketSlug'}
      multiDropdownSelectedItems={this.state.selectedMarkets}
      multiDropdownOnChange={(markets) => this.onMarketSelectionChange(markets)}
      filter={{
        type: FILTER_TYPES.MULTI_DROPDOWN,
        options: this.props.markets.map(({ slug }) => [slug, slug]),
      }}
      filterClassName={'multi-dropdown-select'}
    />
  );

  renderChannelSlug = () => (
    <ItemListHeader
      label={'Channel'}
      dataField={'channelSlug'}
      multiDropdownSelectedItems={this.state.selectedChannels}
      multiDropdownOnChange={(channels) => this.onChannelSelectionChange(channels)}
      filter={{
        type: FILTER_TYPES.MULTI_DROPDOWN,
        options: this.props.channels.map(({ slug }) => [slug, slug]),
      }}
      filterClassName={'multi-dropdown-select'}
    />
  );

  renderStreamType = () => (
    <ItemListHeader
      label={'Stream Type'}
      dataField={'streamType'}
      multiDropdownSelectedItems={this.state.selectedStreamTypes}
      multiDropdownOnChange={(streamTypes) => this.onStreamTypeSelectionChange(streamTypes)}
      filter={{
        type: FILTER_TYPES.MULTI_DROPDOWN,
        options: this.props.streamTypes.map((type) => [type, type]),
      }}
      filterClassName={'multi-dropdown-select'}
    />
  );

  renderSsaiEnabled = () => (
    <ItemListHeader
      label={'SSAI Enabled'}
      dataField={'ssaiEnabled'}
      dataFormat={({ ssaiEnabled }) => (ssaiEnabled ? 'Yes' : 'No')}
      filter={{
        type: FILTER_TYPES.DROPDOWN,
        options: ['Yes', 'No'],
      }}
    />
  );

  renderDrmEnabled = () => (
    <ItemListHeader
      label={'DRM Enabled'}
      dataField={'drmEnabled'}
      dataFormat={({ drmEnabled }) => (drmEnabled ? 'Yes' : 'No')}
      filter={{
        type: FILTER_TYPES.DROPDOWN,
        options: ['Yes', 'No'],
      }}
    />
  );

  renderFullHdEnabled = () => (
    <ItemListHeader
      label={'Full HD Enabled'}
      dataField={'fullHdEnabled'}
      dataFormat={({ fullHdEnabled }) => (fullHdEnabled ? 'Yes' : 'No')}
      filter={{
        type: FILTER_TYPES.DROPDOWN,
        options: ['Yes', 'No'],
      }}
    />
  );

  renderSwitchPrimaryOrBackupForChannel9SydneyOrBrisbane = (channelSlug, marketSlug, primaryOrBackup) => {
    return (
      <ModalItem
        title={`Use ${channelSlug}(${marketSlug}) ${primaryOrBackup} stream`}
        form={
          <ConfirmationDialog
            invitation={`Are you sure you wish to Use the ${primaryOrBackup} stream for ${channelSlug}(${marketSlug})?`}
            ok={(reason) =>
              this.props.switchPrimaryBackupForChannel9SydneyOrBrisbane(
                channelSlug,
                marketSlug,
                primaryOrBackup,
                reason
              )
            }
            buttonClass={'warning'}
            buttonLabel={`Use ${primaryOrBackup} stream`}
            reasonRequired
            immediateCloseOnConfirm
          />
        }
      />
    );
  };

  renderStreamCatalog = () => <ExportStreamsCatalog streams={this.props.data.streams} />;
  renderClearFiltersButton = () => (
    <ClearFiltersButton clearFilters={this.clearFiltersSelection} filters={this.props.filters} />
  );

  renderStreamActions = () => (
    <ButtonToolbar className="streams-button-toolbar">
      <ButtonGroup className="action-bar-button-group">
        {this.renderStreamCatalog()}
        {this.renderClearFiltersButton()}
        <NavDropdownMenu
          alignLeft={false}
          component={'div'}
          buttonClass={'btn btn-default'}
          title={<i className={'fa fa-ellipsis-v'}></i>}
          className={'float-right'}
        >
          {this.renderSwitchPrimaryOrBackupForChannel9SydneyOrBrisbane('channel-9', 'sydney', 'primary')}
          {this.renderSwitchPrimaryOrBackupForChannel9SydneyOrBrisbane('channel-9', 'sydney', 'backup')}
          {this.renderSwitchPrimaryOrBackupForChannel9SydneyOrBrisbane('channel-9', 'brisbane', 'primary')}
          {this.renderSwitchPrimaryOrBackupForChannel9SydneyOrBrisbane('channel-9', 'brisbane', 'backup')}
        </NavDropdownMenu>
      </ButtonGroup>
    </ButtonToolbar>
  );

  renderList = () => (
    <ItemList
      id={'list-stream'}
      list={{
        pageData: this.props.data.streams,
        totalCount: this.props.data.count,
      }}
      getItems={this.getStreams}
    >
      {this.renderUrl()}
      {this.renderMarketSlug()}
      {this.renderChannelSlug()}
      {this.renderStreamType()}
      {this.renderSsaiEnabled()}
      {this.renderDrmEnabled()}
      {this.renderFullHdEnabled()}
    </ItemList>
  );

  render = () => (
    <div>
      {this.props.actionSuccessMessage && (
        <SuccessMessageAlert>{this.props.actionSuccessMessage}</SuccessMessageAlert>
      )}
      {this.props.actionErrorMessage && (
        <ErrorMessageAlert>{this.props.actionErrorMessage}</ErrorMessageAlert>
      )}
      {this.renderBreadcrumb()}
      {this.renderStreamActions()}
      {this.renderList()}
    </div>
  );
}

StreamView.propTypes = {
  actionSuccessMessage: PropTypes.string,
  actionErrorMessage: PropTypes.string,
  data: PropTypes.object,
  listStreams: PropTypes.func,
  markets: PropTypes.array,
  channels: PropTypes.array,
  streamTypes: PropTypes.array,
  resetItemList: PropTypes.func,
  filters: PropTypes.object,
  switchPrimaryBackupForChannel9SydneyOrBrisbane: PropTypes.func,
};

const mapDispatchToProps = {
  resetItemList,
};

function mapStateToProps(state) {
  return {
    filters: state.itemList.filters,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamView);
