import PropTypes from 'prop-types';
import React from 'react';
import LiveEventGroupForm from 'src/scripts/components/LiveEvent/internal/LiveEventGroupForm';
import { createLiveEventGroup, updateLiveEventGroup } from '../../../scripts/actions/liveEventGroup';
import { getValidationErrorMessage, getValidationErrors } from '../../../scripts/lib/formValidation';
import getValidationRules from '../../../scripts/lib/formValidation/validationRules/liveEventGroupForm';
import _isEmpty from 'lodash/isEmpty';

export default class LiveEventGroupFormLoader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      errorMessage: '',
      validationErrors: {},
      liveEventGroup: props.liveEventGroup,
    };

    this.saveButtonClicked = false;
  }

  onPropertyChange = (prop) => (event) => {
    const value = event.target ? event.target.value : event;

    this.setState(
      (prevState) => ({
        ...prevState,
        liveEventGroup: {
          ...prevState.liveEventGroup,
          [prop]: value,
        },
      }),
      () => {
        /**
         * If the save button has not been clicked, do not validate
         * on property change.
         *
         * Otherwise, validate on every change so that existing errors
         * will disappear once a valid input has been provided.
         */
        if (this.saveButtonClicked) {
          this.validateForm();
        }
      }
    );
  };

  submitForm = (extraData) => {
    const { uploadedImages } = extraData;

    if (this.validateForm()) {
      const liveEventGroup = this.attachImagesToLiveEventGroup(uploadedImages);

      this.setState({ liveEventGroup }, () => {
        this.saveLiveEventGroup();
      });
    }
  };

  attachImagesToLiveEventGroup(images) {
    const uploadedImages = [];

    for (const key of Object.keys(images)) {
      if (!images[key].isImageRemoved) {
        uploadedImages.push({
          id: images[key].imageId,
          type: images[key].type,
        });
      }
    }

    const liveEventGroup = {
      ...this.state.liveEventGroup,
      images: uploadedImages,
    };

    return liveEventGroup;
  }

  saveLiveEventGroup() {
    this.setState({ saving: true });

    /**
     * Both create and update functions share the same form. Need to call
     * the appropriate function based on whether `id' is valid.
     */
    const saveFunction = this.state.liveEventGroup.id ? updateLiveEventGroup : createLiveEventGroup;

    const onSuccess = (data) => {
      this.setState({ saving: false });
      this.props.close();
      if (this.props.onSuccess) {
        this.props.onSuccess(data.liveEventGroup);
      }
    };

    const onError = (error) =>
      this.setState({
        saving: false,
        errorMessage: error.responseJSON.message,
      });

    saveFunction(this.state.liveEventGroup).then(onSuccess, onError);
  }

  validateForm() {
    this.saveButtonClicked = true;
    const validationErrors = getValidationErrors(this.state.liveEventGroup, getValidationRules());

    if (_isEmpty(validationErrors)) {
      this.setState({ errorMessage: '', validationErrors: {} });
      return true;
    }

    this.setState({ errorMessage: getValidationErrorMessage(validationErrors), validationErrors });
    return false;
  }

  render = () => (
    <LiveEventGroupForm
      liveEventGroup={this.state.liveEventGroup}
      validationErrors={this.state.validationErrors}
      errorMessage={this.state.errorMessage}
      saving={this.state.saving}
      close={this.props.close}
      onPropertyChange={this.onPropertyChange}
      submitForm={this.submitForm}
    />
  );
}

LiveEventGroupFormLoader.propTypes = {
  liveEventGroup: PropTypes.object,
  onSuccess: PropTypes.func,
  close: PropTypes.func,
};

const LIVE_EVENT_GROUP_DEFAULT_HIGHLIGHT_COLOUR = '#02C7E1';
LiveEventGroupFormLoader.defaultProps = {
  liveEventGroup: {
    id: null,
    name: null,
    subtitle: null,
    position: 1,
    ctvPosition: 1,
    description: null,
    seoPageTitle: null,
    seoDescription: null,
    images: [],
    highlightColour: LIVE_EVENT_GROUP_DEFAULT_HIGHLIGHT_COLOUR,
    isChannelGroup: false,
  },
};
