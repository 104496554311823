import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Button, Col, Panel } from 'react-bootstrap';
import DetailsRow from 'src/scripts/components/MediaItem/DetailsRow';
import DetailsList from 'src/scripts/components/MediaItem/DetailsList';
import VideoPreview from 'src/scripts/components/MediaItem/VideoPreview';
import DownloadButton from 'src/scripts/components/DownloadButton';
import DeleteButton from 'src/scripts/components/DeleteButton';
import GenerateThumbnails from 'src/scripts/components/GenerateThumbnails';
import FlexboxWrapper from 'src/scripts/components/FlexboxWrapper';
import ContextNavigation from 'src/scripts/components/ContextNavigation';
import { createViewModel } from 'src/scripts/components/MediaItem/viewModel';
import { getSignedVideoSourceUrl, getSignedCaptionSourceUrl } from 'src/scripts/actions/video';
import { deleteCaption } from 'src/scripts/actions/ui/caption';
import {
  getCaptionIsDeletingState,
  getCaptionHasJustBeenDeletedState,
  getCaptionHasJustFailedToDeleteState,
  getCaptionErrorMessage,
} from 'src/scripts/reducers/ui/caption/videos';
import { showConfirmationModal, hideConfirmationModal } from 'src/scripts/actions/confirmation';
import CuePointNavigator from './CuePointNavigator';
import EndPointNavigator from './EndPointNavigator';
import { episodeImageTypeData } from 'src/scripts/lib/episodeImageTypes';
import { clipImageTypeData } from '../../lib/clipImageTypes.js';
import { IMAGE_NOT_FOUND_URL } from 'src/scripts/constants/common';

export class DetailsView extends React.Component {
  disableReTranscodeVideoCloudButton() {
    const illegalVideoStatuses = [
      'NEW',
      'UPLOADED',
      'CREATING_IN_BRIGHTCOVE',
      'CREATED_IN_BRIGHTCOVE',
      'TRANSCODING',
    ];
    return (
      this.props.detailsView.disableReingestVideoCloud === true ||
      _.includes(illegalVideoStatuses, this.props.model.video.status) ||
      !this.props.model.video.videoFileName ||
      this.props.disableEdit
    );
  }

  render() {
    const viewModel = createViewModel(this.props.model, this.props.resource);

    const renderBrightcoveVideoCloudReTranscode = (label) => {
      if (label !== 'Brightcove Video Cloud Status') {
        return '';
      }
      return (
        <Button
          disabled={this.disableReTranscodeVideoCloudButton()}
          className="re-ingest-video-cloud"
          bsStyle="link"
          onClick={this.props.transcodeBrightcoveVideoCloud}
        >
          Re-Transcode
        </Button>
      );
    };
    const renderVideoDetailsRow = (row) => {
      if (row.download) {
        return (
          <DownloadButton
            fileName={row.value}
            signedMediaSourceUrl={this.props.detailsView.signedVideoSourceUrl}
            getSignedMediaSourceUrl={this.props.getSignedVideoSourceUrl.bind(
              null,
              this.props.resourceGroup,
              this.props.model.video.id
            )}
          />
        );
      }
      if (row.downloadCaptions) {
        const deleteCaptionConfirmationDescriptions = [
          'Are you sure you want to proceed with deleting the caption associated with this asset? ' +
            'Please note that this action cannot be undone once started.',
        ];
        const deleteCaptionConfirmationHandler = () => this.props.deleteCaption(this.props.model.video.id);
        const deleteCaptionHandler = () =>
          this.props.showConfirmationModal(
            deleteCaptionConfirmationDescriptions,
            deleteCaptionConfirmationHandler
          );
        return (
          <FlexboxWrapper className="caption-actions" row>
            <DownloadButton
              fileName={row.value}
              signedMediaSourceUrl={this.props.detailsView.signedCaptionSourceUrl}
              getSignedMediaSourceUrl={this.props.getSignedCaptionSourceUrl.bind(
                null,
                this.props.resourceGroup,
                this.props.model.video.id
              )}
              disabled={this.props.hasCaptionJustBeenDeleted}
            />
            <DeleteButton
              isDeleting={this.props.isCaptionDeleting}
              onClick={deleteCaptionHandler}
              hasJustDeleted={this.props.hasCaptionJustBeenDeleted}
              hasJustFailedToDelete={this.props.hasCaptionJustFailedToDelete}
              errorMessage={this.props.captionErrorMessage}
              disabled={this.props.hasCaptionJustBeenDeleted}
            >
              Delete Caption
            </DeleteButton>
          </FlexboxWrapper>
        );
      }
      return row.value;
    };
    const generateCaptionPropsIfCaptionsExist = () => {
      if (this.props.model.video && this.props.model.video.captionsS3Key) {
        return {
          signedCaptionSourceUrl: this.props.detailsView.signedCaptionSourceUrl,
          getSignedCaptionSourceUrl: this.props.getSignedCaptionSourceUrl.bind(
            null,
            this.props.resourceGroup,
            this.props.model.video.id
          ),
        };
      }
      return {};
    };
    const renderContentOrigin = () => {
      if (viewModel.contentOrigin) {
        return (
          <DetailsRow
            className="contentOriginDetails"
            header="Content Origin"
            detailsList={viewModel.contentOrigin}
          />
        );
      }
      return null;
    };

    const renderGenerateThumbnailsButton = () => {
      const stateToBeExcluded = ['DRAFT', 'VIDEO_PROCESSING', 'ARCHIVED', 'DELETING', 'DELETION_FAILED'];
      if (this.props.resource === 'episode' && this.props.model.video.videoFileName) {
        if (
          !stateToBeExcluded.includes(this.props.model.state) &&
          this.props.model.video.status === 'TRANSCODED'
        ) {
          return <GenerateThumbnails episode={this.props.model} />;
        }
      }
      return null;
    };

    const renderImagePanel = () => {
      if (this.props.resource === 'episode' && viewModel.images) {
        const getImageUrl = (imageType) => {
          const matchedImageForType = viewModel.images.find((image) => image.type === imageType);

          if (!matchedImageForType) return IMAGE_NOT_FOUND_URL;
          return matchedImageForType.url;
        };

        return (
          <div>
            {episodeImageTypeData.map((imageTypeData) => {
              return (
                <Panel header={imageTypeData.header}>
                  <div className={imageTypeData.wrapperClassName}>
                    <img className="season-image" src={getImageUrl(imageTypeData.type)} />
                  </div>
                </Panel>
              );
            })}
          </div>
        );
      } else if (this.props.resource === 'clip') {
        const getImageUrl = (imageType) => {
          if (!viewModel || !viewModel.images || !viewModel.images.length) {
            return IMAGE_NOT_FOUND_URL;
          }

          const matchedImageForType = viewModel.images.find((image) => image.type === imageType);

          if (!matchedImageForType) return IMAGE_NOT_FOUND_URL;

          return matchedImageForType.url;
        };

        return (
          <div className="details-row">
            <Panel header={'Clip Image'}>
              <div className={'clip-detail-image-wrapper'}>
                <img className="details-image" src={viewModel.image.url} />
              </div>
            </Panel>

            {clipImageTypeData.map((imageType) => {
              return (
                <Panel header={imageType.header}>
                  <div className={imageType.wrapperClassName}>
                    <img className="details-image" src={getImageUrl(imageType.type)} />
                  </div>
                </Panel>
              );
            })}
          </div>
        );
      }

      return (
        <div className="details-row">
          <img className="details-image" src={viewModel.image.url} />
        </div>
      );
    };

    const getVideoDuration = () => {
      if (this.props.videoPlayer) {
        const videoPlayerDuration = this.props.videoDuration;
        if (videoPlayerDuration >= 0) {
          return videoPlayerDuration;
        }
      }
      return null;
    };

    const setVideoTime = (time) => {
      if (this.props.videoPlayer) {
        if (time >= 0) {
          this.props.videoPlayer.currentTime(time);
        }
      }
    };

    const renderVideoNavigation = () => {
      const videoDuration = getVideoDuration();
      return this.props.resource === 'episode' ? (
        <DetailsRow header="Video Navigation">
          <CuePointNavigator
            videoCuePoints={this.props.model.video.cuePoints}
            setVideoTime={setVideoTime}
            videoDuration={videoDuration}
          />
          <EndPointNavigator videoEndPoint={videoDuration} setVideoTime={setVideoTime} />
          <span style={{ display: 'block', float: 'right', color: '#808080' }}>
            Hours : Minutes : Seconds . Milliseconds
          </span>
        </DetailsRow>
      ) : null;
    };

    return (
      <div>
        <ContextNavigation breadcrumbList={this.props.breadcrumbList} />
        <Row className="details-toolbar">{this.props.workflowActions}</Row>
        <Row>
          <Col md={6}>
            <DetailsRow
              showGrid
              className="metadataDetails"
              header="Metadata"
              detailsList={viewModel.metadata}
            />
            {this.props.resource === 'networkClip' || this.props.resource === 'clip' ? (
              <DetailsRow
                header="SEO Metadata"
                className="seo-metadata-details"
                detailsList={viewModel.seoMetadata}
              />
            ) : null}
            <DetailsRow
              className="schedulingDetails"
              header="Scheduling"
              detailsList={viewModel.scheduling}
            />

            {renderContentOrigin()}

            <DetailsRow className="detailsSectionPanel" header="Video">
              {viewModel.video.map((row, index) => {
                return (
                  <div key={index} className="details-row row">
                    <div className="details-key">{row.label}</div>
                    {row.value ? (
                      <div className="details-value">
                        {renderVideoDetailsRow(row)}
                        {renderBrightcoveVideoCloudReTranscode(row.label)}
                      </div>
                    ) : (
                      <DetailsList itemList={row.list} />
                    )}
                  </div>
                );
              })}
            </DetailsRow>
            {this.props.resource !== 'networkClip' && (
              <DetailsRow className="producer-notes" header="Notes" detailsList={viewModel.producerNotes} />
            )}
          </Col>
          <Col md={6}>
            {renderImagePanel()}
            {this.props.model.video.videoFileName ? (
              <div className="details-row" style={{ 'margin-bottom': '10px' }}>
                <VideoPreview
                  videoCuePoints={this.props.model.video.cuePoints}
                  signedVideoSourceUrl={this.props.detailsView.signedVideoSourceUrl}
                  getSignedVideoSourceUrl={this.props.getSignedVideoSourceUrl.bind(
                    null,
                    this.props.resourceGroup,
                    this.props.model.video.id
                  )}
                  {...generateCaptionPropsIfCaptionsExist()}
                />
              </div>
            ) : null}
            {renderVideoNavigation()}
            {renderGenerateThumbnailsButton()}
          </Col>
        </Row>
      </div>
    );
  }
}

DetailsView.propTypes = {
  workflowActions: PropTypes.element,
  transcodeBrightcoveVideoCloud: PropTypes.func,
  model: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  resourceGroup: PropTypes.string,
  breadcrumbList: PropTypes.array.isRequired,
  disableEdit: PropTypes.bool,
  detailsView: PropTypes.object.isRequired,
  getSignedVideoSourceUrl: PropTypes.func.isRequired,
  getSignedCaptionSourceUrl: PropTypes.func.isRequired,
  isCaptionDeleting: PropTypes.bool,
  hasCaptionJustBeenDeleted: PropTypes.bool,
  hasCaptionJustFailedToDelete: PropTypes.bool,
  captionErrorMessage: PropTypes.string,
  deleteCaption: PropTypes.func,
  showConfirmationModal: PropTypes.func,
  hideConfirmationModal: PropTypes.func,
  videoPlayer: PropTypes.object,
  videoDuration: PropTypes.number,
};

function mapStateToProps(state, ownProps) {
  return {
    detailsView: state.mediaItemDetailsView,
    isCaptionDeleting: getCaptionIsDeletingState(state, ownProps.model.video.id),
    hasCaptionJustBeenDeleted: getCaptionHasJustBeenDeletedState(state, ownProps.model.video.id),
    hasCaptionJustFailedToDelete: getCaptionHasJustFailedToDeleteState(state, ownProps.model.video.id),
    captionErrorMessage: getCaptionErrorMessage(state, ownProps.model.video.id),
    videoDuration: state.videoPlayer.duration,
    videoPlayer: state.videoPlayer.player,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSignedVideoSourceUrl: (resourceGroup, videoId) =>
      dispatch(getSignedVideoSourceUrl(resourceGroup, videoId)),
    getSignedCaptionSourceUrl: (resourceGroup, videoId) =>
      dispatch(getSignedCaptionSourceUrl(resourceGroup, videoId)),
    deleteCaption: (videoId) => dispatch(deleteCaption(videoId)),
    showConfirmationModal: (description, confirmationHandler) =>
      dispatch(showConfirmationModal(description, confirmationHandler)),
    hideConfirmationModal: () => dispatch(hideConfirmationModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsView);
