import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Input, Button, Panel } from 'react-bootstrap';
import { Creatable } from 'react-select';
import {
  isUserInputNumeric,
  isPositiveValidNumber,
  isNumberBetween,
  validateContentSeries,
} from 'src/scripts/lib/validation';
import {
  SQUARE,
  HORIZONTAL_A,
  BACKGROUND,
  CARD,
  LOGO,
  POSTER,
  FEATURED_CARD,
  FEATURED_BACKGROUND,
  CTV_CAROUSEL,
  CENTERED_IMAGE,
  CROPPED,
} from 'src/scripts/lib/imageTypes';
import { saveSeason, updateSeason } from 'src/scripts/actions/season';
import ImageUpload from 'src/scripts/components/ImageUpload';
import TVSeriesSelect from 'src/scripts/components/TVSeriesSelect';
import MultiInputList from 'src/scripts/components/MultiInputList';
import { GENDER_GROUPS, CONTENT_GROUPS } from 'src/scripts/lib/videoAdMetadataGroups';
import { IMAGES } from 'src/scripts/lib/resourceGroups';
import { SEASON_IMAGE_SQUARE_REF, SEASON_IMAGE_LANDSCAPE_REF } from 'src/scripts/lib/imageRefs';
import { showFormValidation } from 'src/scripts/actions/form';
import { isFormValid } from 'src/scripts/lib/formValidation/index';
import getValidationRules from 'src/scripts/lib/formValidation/validationRules/seasonForm';
import SeoMetadata from 'src/scripts/components/Season/internal/SeoMetadata';
import { getEpisodeDisplayNameExample } from 'src/scripts/lib/episodeDisplayNames';
import MultiImageUpload from 'src/scripts/components/MultiImageUpload';
import FormErrorMessage from 'src/scripts/components/FormErrorMessage';
import DefaultContentIdSection from 'src/scripts/components/Season/internal/DefaultContentIdSection';
import DateTimePicker from 'src/scripts/components/DateTimePicker';
import ClassificationSection from './ClassificationSection';
import environment from 'config/environment';
import RecommendedCharacterCountPopover from '../../RecommendedCharacterCountPopover';
import ContentFlagSelect from '../../ContentFlagSelect';
import AdditionalMetadata from './AdditionalMetadata';
import { getSportTypes } from '../../../actions/apiWrappers/vmsApi/tv.js';
import { seasonClassificationCodes } from '../../../reducers/seasonForm';
import { isDefaultOptionSelected } from 'src/scripts/lib/util';

const mapToSelectDropdownOptions = (options) => {
  return options.map((option) => ({ label: option, value: option }));
};

export const SERIES_PAGE_LIVE_RAIL_DISPLAY_NAME_DEFAULT = 'Live & Upcoming';
const SERIES_PAGE_LIVE_RAIL_DISPLAY_NAME_OPTIONS = [SERIES_PAGE_LIVE_RAIL_DISPLAY_NAME_DEFAULT, 'Watch Live'];

export const SERIES_PAGE_EPISODES_RAIL_DISPLAY_NAME_DEFAULT_BOXSET = 'Episodes';
export const SERIES_PAGE_EPISODES_RAIL_DISPLAY_NAME_DEFAULT = 'Latest Episodes';
const SERIES_PAGE_EPISODES_RAIL_DISPLAY_NAME_OPTIONS = [
  SERIES_PAGE_EPISODES_RAIL_DISPLAY_NAME_DEFAULT,
  'Episodes',
  'Latest Bulletins',
  'Full Match Replays',
  'Replays',
  'Full Replays',
];

export const SERIES_PAGE_CLIPS_RAIL_DISPLAY_NAME_DEFAULT = 'Latest Clips';
const SERIES_PAGE_CLIPS_RAIL_DISPLAY_NAME_OPTIONS = [
  SERIES_PAGE_CLIPS_RAIL_DISPLAY_NAME_DEFAULT,
  'Preview Clip',
  'Latest Highlights',
  'Highlights',
  'Match Highlights',
  'Latest News',
  'Exclusives',
  'Exclusive & Unseen',
];

export const SERIES_PAGE_LIVE_RAIL_DISPLAY_NAME_SELECT_OPTIONS = mapToSelectDropdownOptions(
  SERIES_PAGE_LIVE_RAIL_DISPLAY_NAME_OPTIONS
);
export const SERIES_PAGE_EPISODES_RAIL_DISPLAY_NAME_SELECT_OPTIONS = mapToSelectDropdownOptions(
  SERIES_PAGE_EPISODES_RAIL_DISPLAY_NAME_OPTIONS
);
export const SERIES_PAGE_CLIPS_RAIL_DISPLAY_NAME_SELECT_OPTIONS = mapToSelectDropdownOptions(
  SERIES_PAGE_CLIPS_RAIL_DISPLAY_NAME_OPTIONS
);

const createNewOptionPromptTextCreator = (label) => `Custom rail title: ${label}`;

function compileSelectOptions(existingSelectOptions, selectValue) {
  const allSelectOptions = selectValue
    ? existingSelectOptions.concat([{ label: selectValue, value: selectValue }])
    : existingSelectOptions;
  return _.uniqWith(allSelectOptions, _.isEqual);
}

export class SeasonForm extends React.Component {
  constructor(props) {
    super(props);
    const mode = this.props.season.id ? 'edit' : 'add';
    const catalogCode = this.props.season.catalogCode ? this.props.season.catalogCode : null;
    this.state = {
      mode,
      catalogCode,
      prevCatalogCode: null,
      seasonSportTypes: [],
    };

    this.render = this.render.bind(this);
    this.saveSeason = this.saveSeason.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onTVSeriesChange = this.onTVSeriesChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onSeasonNumberChange = this.onSeasonNumberChange.bind(this);
    this.onSeasonYearChange = this.onSeasonYearChange.bind(this);
    this.onPublishedDateChange = this.onPublishedDateChange.bind(this);
    this.onCatalogCodeChange = this.onCatalogCodeChange.bind(this);
    this.onOnAirScheduleChange = this.onOnAirScheduleChange.bind(this);
    this.onContentFlagChange = this.onContentFlagChange.bind(this);
    this.onChannelChange = this.onChannelChange.bind(this);
    this.episodeDisplayNameFormatChange = this.episodeDisplayNameFormatChange.bind(this);
    this.onEpisodesGeoBlockedChange = this.onEpisodesGeoBlockedChange.bind(this);
    this.onClipsGeoBlockedChange = this.onClipsGeoBlockedChange.bind(this);
    this.onEpisodesWatermarkEnabledChange = this.onEpisodesWatermarkEnabledChange.bind(this);
    this.onClipsWatermarkEnabledChange = this.onClipsWatermarkEnabledChange.bind(this);
    this.onGenderSkewChange = this.onGenderSkewChange.bind(this);
    this.onContentGroupChange = this.onContentGroupChange.bind(this);
    this.onSeriesPageLiveRailEnabledChange = this.onSeriesPageLiveRailEnabledChange.bind(this);
    this.onClassificationRatingChange = this.onClassificationRatingChange.bind(this);
  }

  componentWillMount() {
    this.getSportTypes();
  }

  async getSportTypes() {
    // Call get sport type endpoint.
    const sportTypesResult = await getSportTypes();

    this.setState({
      seasonSportTypes: sportTypesResult.sportTypes,
    });
  }

  onDescriptionChange(event) {
    this.props.updateSeason({
      description: event.target.value,
    });
  }

  onNameChange(event) {
    this.props.updateSeason({
      name: event.target.value,
    });
  }

  onCatalogCodeChange(event) {
    this.props.updateSeason({
      catalogCode: event.target.value,
    });
  }

  handleCatalogCodes = () => {
    this.setState((prevState) => {
      return {
        catalogCode: this.props.season.catalogCode,
        prevCatalogCode: prevState.catalogCode,
      };
    });
  };

  onAllCatalogCodesChange = (catalogCodes) => {
    const allCatalogCodes = catalogCodes.map((item) => item.value);
    this.props.updateSeason({
      allCatalogCodes,
    });
  };

  onOnAirScheduleChange(event) {
    this.props.updateSeason({
      onAirScheduleDate: event.target.value,
    });
  }

  onContentFlagChange = (contentFlag) => {
    const value = contentFlag ? contentFlag.value : null;
    this.props.updateSeason({
      contentFlag: value,
    });
  };

  onTVSeriesChange(tvSeriesId, tvSeries) {
    this.setState({
      tvSeries: tvSeries ? tvSeries.name : '',
    });
    this.props.updateSeason({
      tvSeriesId: tvSeriesId ? parseInt(tvSeriesId, 10) : null,
    });
  }

  onChannelChange(event) {
    const channelId = parseInt(event.target.value, 10);
    this.props.updateSeason({
      channelId: channelId > 0 ? channelId : null,
    });
  }

  onSeasonNumberChange(event) {
    const number = event.target.value ? parseInt(event.target.value, 10) : null;
    this.props.updateSeason({
      seasonNumber: number,
    });
  }

  onSeasonYearChange(event) {
    const number = event.target.value ? parseInt(event.target.value, 10) : null;
    this.props.updateSeason({
      seasonYear: number,
    });
  }

  onPublishedDateChange(date) {
    this.props.updateSeason({
      publishedDate: date,
    });
  }

  onAvailableContentSeriesChange(availableContentSeries) {
    this.props.updateSeason({
      availableContentSeries,
    });
  }

  onClassificationsChange(classifications) {
    this.props.updateSeason({
      classifications,
    });
  }

  onClassificationRatingChange(event) {
    this.props.updateSeason({
      classificationRating: !isDefaultOptionSelected(this.refs.videoClassification)
        ? event.target.value
        : null,
    });
  }

  onEpisodesGeoBlockedChange(event) {
    this.props.updateSeason({
      episodesGeoBlocked: event.target.checked,
    });
  }

  onClipsGeoBlockedChange(event) {
    this.props.updateSeason({
      clipsGeoBlocked: event.target.checked,
    });
  }

  onEpisodesWatermarkEnabledChange(event) {
    this.props.updateSeason({
      episodesWatermarkEnabled: event.target.checked,
    });
  }

  onClipsWatermarkEnabledChange(event) {
    this.props.updateSeason({
      clipsWatermarkEnabled: event.target.checked,
    });
  }

  onGenderSkewChange(event) {
    this.props.updateSeason({
      genderSkew: event.target.value,
    });
  }

  onContentGroupChange(event) {
    this.props.updateSeason({
      contentGroup: event.target.value,
    });
  }

  onProducerNotesInput(event) {
    this.props.updateSeason({ producerNotes: event.target.value });
  }

  onBoxSetChange = (event) => {
    this.props.updateSeason({
      isBoxSet: event.target.checked,
    });
  };

  onSeriesPageLiveRailEnabledChange = (event) => {
    this.props.updateSeason({
      seriesPageLiveRailEnabled: event.target.checked,
    });
  };

  onSeriesPageLiveRailDisplayNameChange = (selectedOption) => {
    const nextValue = selectedOption ? selectedOption.value : null;
    this.props.updateSeason({
      seriesPageLiveRailDisplayName: nextValue,
    });
  };

  onSeriesPageEpisodesRailEnabledChange = (event) => {
    this.props.updateSeason({
      seriesPageEpisodesRailEnabled: event.target.checked,
    });
  };

  onSeriesPageEpisodesRailDisplayNameChange = (selectedOption) => {
    const nextValue = selectedOption ? selectedOption.value : null;
    this.props.updateSeason({
      seriesPageEpisodesRailDisplayName: nextValue,
    });
  };

  onSeriesPageClipsRailEnabledChange = (event) => {
    this.props.updateSeason({
      seriesPageClipsRailEnabled: event.target.checked,
    });
  };

  onSeriesPageClipsRailDisplayNameChange = (selectedOption) => {
    const nextValue = selectedOption ? selectedOption.value : null;
    this.props.updateSeason({
      seriesPageClipsRailDisplayName: nextValue,
    });
  };

  onDefaultAdsEnabledChange = (event) => {
    this.props.updateSeason({
      defaultAdsEnabled: event.target.checked,
    });
  };

  getImageByType(images, type) {
    return images ? images.find((image) => image.type === type) : undefined;
  }

  getImagesWithTypes(images, types) {
    return images ? images.filter((image) => types.includes(image.type)) : [];
  }

  getSupportedImageTypes() {
    return [BACKGROUND, CARD, LOGO, FEATURED_CARD, FEATURED_BACKGROUND, CTV_CAROUSEL];
  }

  setUploadedImages(season) {
    const uploadedImages = [];
    const imageProps = this.props.multiImageUpload;
    _.forOwn(imageProps, (value, key) => {
      uploadedImages.push({
        imageId: imageProps[key].imageId,
        type: imageProps[key].type,
        isImageRemoved: imageProps[key].isImageRemoved,
      });
    });
    season.uploadedImages = uploadedImages;
  }

  episodeDisplayNameFormatChange(event) {
    this.props.updateSeason({
      episodeDisplayNameFormat: event.target.value,
    });
  }

  isSeasonYearValid() {
    const enteredYear = this.props.season.seasonYear;
    return !enteredYear || (isPositiveValidNumber(enteredYear) && isNumberBetween(enteredYear, 1899, 2999));
  }

  isVideoGroupValid(videoGroup) {
    return this.state.mode === 'edit' || (videoGroup !== '0' && videoGroup !== undefined);
  }

  isSubmitDisabled() {
    const seasonYearInvalid = !this.isSeasonYearValid();
    const imagesUploading = _.pickBy(this.props.multiImageUpload, (image) => image.uploading);
    const areImagesUploading = imagesUploading ? _.keys(imagesUploading).length > 0 : false;
    return (
      seasonYearInvalid ||
      areImagesUploading ||
      this.props.landscapeImageUpload.uploading ||
      this.props.squareImageUpload.uploading ||
      this.props.saving
    );
  }

  isCloseDisabled() {
    return this.props.saving;
  }

  addCatalogCodeToCatalogCodesIfNotExists = (season) => {
    if (
      this.props.season.catalogCode &&
      !this.props.season.allCatalogCodes.includes(this.props.season.catalogCode)
    ) {
      season.allCatalogCodes = [...this.props.season.allCatalogCodes, this.props.season.catalogCode];
    } else {
      season.allCatalogCodes = this.props.season.allCatalogCodes;
    }
  };

  removePrevCatalogCodeFromCatalogCodes = (season) => {
    if (this.state.prevCatalogCode && season.allCatalogCodes.includes(this.state.prevCatalogCode)) {
      season.allCatalogCodes = season.allCatalogCodes.filter(
        (catalogCode) => catalogCode !== this.state.prevCatalogCode
      );
    }
  };

  processAllCatalogCodesOnSave(season) {
    if (this.props.season.allCatalogCodes && this.props.season.allCatalogCodes.length) {
      this.addCatalogCodeToCatalogCodesIfNotExists(season);
      this.removePrevCatalogCodeFromCatalogCodes(season);
    } else if (season.catalogCode) {
      season.allCatalogCodes = [season.catalogCode];
    } else {
      season.allCatalogCodes = this.props.season.allCatalogCodes;
    }
  }

  getSportTypeObjectId(sportTypeIdOrObject) {
    if (!sportTypeIdOrObject) return null;

    const sportTypeId = parseInt(sportTypeIdOrObject, 10);

    // Check if the sportType is an object or number, if neither set to null.
    if (!isNaN(sportTypeId)) {
      return sportTypeId;
    } else if (typeof sportTypeIdOrObject === 'object') {
      return sportTypeIdOrObject.id;
    }

    return null;
  }

  getSportTypeDisplayName() {
    const sportTypeDisplayName = this.props.season.sportTypeDisplayName;

    // Check if display name exists without spaces around it.
    if (!!sportTypeDisplayName && !!sportTypeDisplayName.trim()) {
      return sportTypeDisplayName.trim();
    }

    return null;
  }

  saveSeason(event) {
    event.preventDefault();
    const nonEmptyContentSeries = this.props.season.availableContentSeries
      ? this.props.season.availableContentSeries.filter((contentSeries) => contentSeries)
      : null;
    const season = {
      id: this.props.season.id,
      name: this.props.season.name && this.props.season.name.trim(),
      description: this.props.season.description,
      seasonNumber: this.props.season.seasonNumber,
      seasonYear: this.props.season.seasonYear,
      publishedDate: this.props.season.publishedDate ? this.props.season.publishedDate : null,
      tvSeriesId: this.props.season.tvSeriesId,
      catalogCode: this.props.season.catalogCode,
      onAirScheduleDate: this.props.season.onAirScheduleDate,
      clipsGeoBlocked: this.props.season.clipsGeoBlocked,
      episodesGeoBlocked: this.props.season.episodesGeoBlocked,
      channelId: this.props.season.channelId,
      episodeDisplayNameFormat: this.props.season.episodeDisplayNameFormat,
      availableContentSeries: nonEmptyContentSeries,
      episodesWatermarkEnabled: this.props.season.episodesWatermarkEnabled,
      clipsWatermarkEnabled: this.props.season.clipsWatermarkEnabled,
      genderSkew: this.props.season.genderSkew,
      contentFlag: this.props.season.contentFlag,
      contentGroup: this.props.season.contentGroup,
      seoTvSeries: this.props.season.seoTvSeries ? this.props.season.seoTvSeries : null,
      seoPageTitle: this.props.season.seoPageTitle ? this.props.season.seoPageTitle : null,
      seoDescription: this.props.season.seoDescription ? this.props.season.seoDescription : null,
      isBoxSet: this.props.season.isBoxSet,
      classifications: this.props.season.classifications,
      defaultContentIdChannel: this.props.season.defaultContentIdChannel,
      defaultContentIdUsagePolicy: this.props.season.defaultContentIdUsagePolicy,
      defaultContentIdMatchPolicy: this.props.season.defaultContentIdMatchPolicy,
      contentIdOwnershipStrategy: this.props.season.contentIdOwnershipStrategy,
      contentIdOwnershipCountries: this.props.season.contentIdOwnershipCountries,
      defaultAdsEnabled: this.props.season.defaultAdsEnabled,
      seriesPageLiveRailEnabled: this.props.season.seriesPageLiveRailEnabled,
      seriesPageEpisodesRailEnabled: this.props.season.seriesPageEpisodesRailEnabled,
      seriesPageClipsRailEnabled: this.props.season.seriesPageClipsRailEnabled,
      seriesPageLiveRailDisplayName: this.props.season.seriesPageLiveRailDisplayName,
      seriesPageEpisodesRailDisplayName: this.props.season.seriesPageEpisodesRailDisplayName,
      seriesPageClipsRailDisplayName: this.props.season.seriesPageClipsRailDisplayName,
      sportTypeId: this.getSportTypeObjectId(this.props.season.sportType),
      sportTypeDisplayName: this.getSportTypeDisplayName(),
      classificationRating: this.props.season.classificationRating,
      producerNotes: this.props.season.producerNotes,
    };

    if (this.props.landscapeImageUpload.imageId) {
      season.imageId = this.props.landscapeImageUpload.imageId;
    }

    this.processAllCatalogCodesOnSave(season);

    if (this.props.squareImageUpload.imageId) {
      season.squareImageId = this.props.squareImageUpload.imageId;
    }
    this.setUploadedImages(season);
    if (!isFormValid(season, getValidationRules())) {
      this.props.showFormValidation();
      return;
    }
    this.props.saveSeason(season);
  }

  renderAllCatalogCodesField = () => {
    const catalogCodes = this.props.season.allCatalogCodes
      ? this.props.season.allCatalogCodes.map((item) => {
          return { label: item, value: item };
        })
      : [];
    let catalogCodeExists;
    if (catalogCodes && catalogCodes.length && this.state.catalogCode) {
      catalogCodeExists = catalogCodes.find(({ label }) => label === this.state.catalogCode);
    }
    if (this.state.catalogCode && !catalogCodeExists) {
      catalogCodes.push({ label: this.state.catalogCode, value: this.state.catalogCode });
    }
    if (this.state.prevCatalogCode && this.state.catalogCode !== this.state.prevCatalogCode) {
      const previousCatalogIndex = catalogCodes.findIndex((x) => x.label === this.state.prevCatalogCode);
      if (previousCatalogIndex >= 0) {
        catalogCodes.splice(previousCatalogIndex, 1);
      }
    }
    return (
      <div className="form-group">
        <label ref="allCatalogCodesLabel">All Catalog Codes</label>
        <Creatable
          ref="allCatalogCodesInput"
          multi
          value={catalogCodes}
          placeholder="Add Catalog Code And Press Enter"
          arrowRenderer={() => null}
          menuContainerStyle={{ display: 'none' }}
          onChange={this.onAllCatalogCodesChange}
        />
      </div>
    );
  };

  recommendedNameCharacterCount = 30;
  recommendedDescriptionCharacterCount = 179;

  render() {
    const nameCharacterCount = this.props.season.name ? this.props.season.name.length : 0;
    const descriptionCharacterCount = this.props.season.description
      ? this.props.season.description.length
      : 0;

    return (
      <div>
        <form className="form" onSubmit={this.saveSeason} ref="seasonForm" data-pw="season-form">
          <Panel header="Main" bsStyle="primary" eventKey="1">
            <Input
              type="select"
              label="Library Name"
              disabled
              className="select-library"
              ref="selectLibrary"
              id="select-library"
              labelClassName="required"
            >
              <option value="TV">TV</option>
            </Input>
            <TVSeriesSelect
              ref="selectTVSeries"
              onTVSeriesChange={this.onTVSeriesChange}
              initialTvSeries={this.props.season.partOfSeries}
              disabled={this.state.mode === 'edit'}
              hasValidationErrors={!!this.props.validationErrors.tvSeriesId}
            />
            <div className="form-group">
              <label ref="nameLabel">Season Name</label>
              <div
                id="name-character-limit-label"
                className={`pull-right ${
                  nameCharacterCount > this.recommendedNameCharacterCount ? 'character-count-warning' : ''
                }`}
              >
                <span ref="charCount">
                  {nameCharacterCount} / {this.recommendedNameCharacterCount}
                </span>
                <RecommendedCharacterCountPopover />
              </div>
              <Input
                labelClassName="required"
                type="text"
                placeholder="Season name"
                ref="name"
                id="text-season-name"
                maxLength="100"
                onChange={this.onNameChange}
                value={this.props.season.name}
                bsStyle={this.props.validationErrors.name ? 'error' : null}
                data-pw="text-season-name"
              />
            </div>
            <div className="form-group">
              <label ref="descriptionLabel">Season Description</label>
              <div
                id="description-character-limit-label"
                className={`pull-right ${
                  descriptionCharacterCount > this.recommendedDescriptionCharacterCount
                    ? 'character-count-warning'
                    : ''
                }`}
              >
                <span ref="charCount">
                  {descriptionCharacterCount} / {this.recommendedDescriptionCharacterCount}
                </span>
                <RecommendedCharacterCountPopover />
              </div>
              <Input
                labelClassName="required"
                type="textarea"
                ref="seasonDescription"
                id="text-season-description"
                maxLength="2000"
                placeholder="Add a description of the season"
                onChange={this.onDescriptionChange}
                value={this.props.season.description}
                bsStyle={this.props.validationErrors.description ? 'error' : null}
                data-pw="text-season-description"
              />
            </div>
            <Input
              labelClassName="required"
              type="text"
              label="Season Number"
              ref="seasonNumber"
              id="text-season-number"
              maxLength="4"
              onChange={this.onSeasonNumberChange}
              onKeyPress={isUserInputNumeric}
              value={this.props.season.seasonNumber}
              bsStyle={this.props.validationErrors.seasonNumber ? 'error' : null}
              data-pw="text-season-number"
            />
            <Input
              type="select"
              label="Classification Rating"
              ref="videoClassification"
              id="select-classification"
              className="videoClassification"
              onChange={this.onClassificationRatingChange}
              value={this.props.season.classificationRating}
            >
              <option value="0">Select a classification</option>
              {seasonClassificationCodes.map((classification) => {
                return (
                  <option key={classification.code} value={classification.code}>
                    {classification.code} - {classification.name}
                  </option>
                );
              })}
            </Input>
            <Input
              type="text"
              label="Season Year"
              id="text-season-year"
              ref="seasonYear"
              maxLength="4"
              placeholder="YYYY"
              onChange={this.onSeasonYearChange}
              onKeyPress={isUserInputNumeric}
              value={this.props.season.seasonYear}
              data-pw="text-season-year"
            />
            {!this.isSeasonYearValid() && (
              <div ref="seasonYearError" className="alert alert-danger season-year-error">
                Sorry that year is not valid. Please adjust and try again.
              </div>
            )}
            {!environment.featureFlags.nineVideoFeaturesEnabled && (
              <div>
                <Input
                  type="select"
                  label="Episode Display Name Format"
                  ref="episodeDisplayNameFormat"
                  id="select-episode-display-name-format"
                  onChange={this.episodeDisplayNameFormatChange}
                  value={this.props.season.episodeDisplayNameFormat}
                >
                  <option value="NUMBER_AND_NAME">Ep [Number] [Name]</option>
                  <option value="NUMBER">Ep [Number]</option>
                  <option value="NAME">[Name]</option>
                </Input>
                <span className="episode-display-name-example">
                  <i className="episode-name-example fa fa-info-circle"></i>
                  {getEpisodeDisplayNameExample(this.props.season.episodeDisplayNameFormat)}
                </span>
              </div>
            )}
            <Input
              type="text"
              label="Catalog Code"
              ref="catalogCode"
              id="text-catalog-code"
              maxLength="8"
              onChange={this.onCatalogCodeChange}
              onBlur={this.handleCatalogCodes}
              value={this.props.season.catalogCode}
            />
            {this.renderAllCatalogCodesField()}
            <Input
              type="text"
              label="On Air Schedule"
              ref="onAirScheduleDate"
              id="text-on-air-schedule"
              maxLength="50"
              onChange={this.onOnAirScheduleChange}
              value={this.props.season.onAirScheduleDate}
            />
            {environment.featureFlags.nineVideoFeaturesEnabled && (
              <div className="content-flag-select">
                <label classsName="control-label">Content Flag</label>
                <ContentFlagSelect
                  ref="contentFlagSelect"
                  contentFlagType={'season'}
                  onChange={this.onContentFlagChange}
                  selected={this.props.season.contentFlag}
                />
              </div>
            )}
            <Input
              type="select"
              label="Channel"
              ref="selectChannel"
              id="select-channel"
              onChange={this.onChannelChange}
              value={this.props.season.channelId}
            >
              <option value="0">Select a channel</option>
              {this.props.channels.map((channel) => {
                return (
                  <option key={channel.id} value={channel.id}>
                    {channel.name}
                  </option>
                );
              })}
            </Input>
            {!environment.featureFlags.nineVideoFeaturesEnabled && (
              <Input
                type="checkbox"
                label="Boxset"
                ref="boxSet"
                id="box-set"
                groupClassName="form-inline"
                onChange={this.onBoxSetChange}
                checked={this.props.season.isBoxSet}
              />
            )}
            {!environment.featureFlags.nineVideoFeaturesEnabled && (
              <div className={`form-group ${this.props.validationErrors.publishedDate ? 'error' : ''}`}>
                <label className="control-label">Boxset Publish Date</label>
                <DateTimePicker
                  ref="publishedDate"
                  id="published-date"
                  dateFormatForDisplay="DD-MM-YYYY"
                  onChange={this.onPublishedDateChange}
                  value={this.props.season.publishedDate}
                />
              </div>
            )}
          </Panel>
          <Panel header="Artwork" bsStyle="primary" eventKey="1">
            <ImageUpload
              imageType={HORIZONTAL_A}
              existingImage={this.getImageByType(this.props.season.images, HORIZONTAL_A)}
              label="Horizontal Image"
              id="image-season-landscape"
              resourceGroup={IMAGES.TV}
              imageUploadRef={SEASON_IMAGE_LANDSCAPE_REF}
            />
            <ImageUpload
              imageType={SQUARE}
              existingImage={this.getImageByType(this.props.season.images, SQUARE)}
              className="preview-image-square"
              label="Square Image"
              id="image-season-square"
              resourceGroup={IMAGES.TV}
              imageUploadRef={SEASON_IMAGE_SQUARE_REF}
            />
            <label>New Images</label>
            <MultiImageUpload
              resourceGroup={IMAGES.TV}
              imageTypes={[
                BACKGROUND,
                CARD,
                LOGO,
                POSTER,
                FEATURED_CARD,
                FEATURED_BACKGROUND,
                CTV_CAROUSEL,
                CENTERED_IMAGE,
                CROPPED,
              ]}
              existingImages={this.getImagesWithTypes(this.props.season.images, [
                BACKGROUND,
                CARD,
                LOGO,
                POSTER,
                FEATURED_CARD,
                FEATURED_BACKGROUND,
                CTV_CAROUSEL,
                CENTERED_IMAGE,
                CROPPED,
              ])}
            />
          </Panel>
          {environment.featureFlags.nineVideoFeaturesEnabled && (
            <Panel header="Page Display" bsStyle="primary">
              <Input
                type="checkbox"
                label="Boxset"
                ref="boxSet"
                id="box-set"
                groupClassName="form-inline"
                onChange={this.onBoxSetChange}
                checked={this.props.season.isBoxSet}
              />
              <div className={`form-group ${this.props.validationErrors.publishedDate ? 'error' : ''}`}>
                <label className="control-label">Boxset Publish Date</label>
                <DateTimePicker
                  ref="publishedDate"
                  id="published-date"
                  dateFormatForDisplay="DD-MM-YYYY"
                  onChange={this.onPublishedDateChange}
                  value={this.props.season.publishedDate}
                />
              </div>
              <Input
                type="select"
                label="Episode Display Name Format"
                ref="episodeDisplayNameFormat"
                id="select-episode-display-name-format"
                onChange={this.episodeDisplayNameFormatChange}
                value={this.props.season.episodeDisplayNameFormat}
              >
                <option value="NUMBER_AND_NAME">Ep [Number] [Name]</option>
                <option value="NUMBER">Ep [Number]</option>
                <option value="NAME">[Name]</option>
              </Input>
              <span className="episode-display-name-example">
                <i className="episode-name-example fa fa-info-circle"></i>
                {getEpisodeDisplayNameExample(this.props.season.episodeDisplayNameFormat)}
              </span>
              <div style={{ marginTop: '30px', marginBottom: '10px' }}>
                <label className="control-label">TV Series Page Display</label>
              </div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: '30px' }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '140px' }}>
                  <Input
                    type="checkbox"
                    label="Live"
                    ref="seriesLiveRailEnabled"
                    groupClassName="form-inline"
                    onChange={this.onSeriesPageLiveRailEnabledChange}
                    checked={this.props.season.seriesPageLiveRailEnabled}
                  />
                </div>
                <div style={{ flexGrow: 1 }}>
                  <Creatable
                    ref="seriesPageLiveRailDisplayNameInput"
                    placeholder={SERIES_PAGE_LIVE_RAIL_DISPLAY_NAME_DEFAULT}
                    disabled={!this.props.season.seriesPageLiveRailEnabled}
                    options={compileSelectOptions(
                      SERIES_PAGE_LIVE_RAIL_DISPLAY_NAME_SELECT_OPTIONS,
                      this.props.season.seriesPageLiveRailDisplayName
                    )}
                    onChange={this.onSeriesPageLiveRailDisplayNameChange}
                    value={this.props.season.seriesPageLiveRailDisplayName}
                    promptTextCreator={createNewOptionPromptTextCreator}
                  />
                </div>
              </div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: '30px' }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '140px' }}>
                  <Input
                    type="checkbox"
                    label="Episodes"
                    ref="seriesEpisodesRailEnabled"
                    groupClassName="form-inline"
                    onChange={this.onSeriesPageEpisodesRailEnabledChange}
                    checked={this.props.season.seriesPageEpisodesRailEnabled}
                    style={{ marginBottom: 0 }}
                  />
                </div>
                <div style={{ flexGrow: 1 }}>
                  <Creatable
                    ref="seriesPageEpisodesRailDisplayNameInput"
                    placeholder={
                      this.props.season.isBoxSet
                        ? SERIES_PAGE_EPISODES_RAIL_DISPLAY_NAME_DEFAULT_BOXSET
                        : SERIES_PAGE_EPISODES_RAIL_DISPLAY_NAME_DEFAULT
                    }
                    disabled={!this.props.season.seriesPageEpisodesRailEnabled}
                    options={compileSelectOptions(
                      SERIES_PAGE_EPISODES_RAIL_DISPLAY_NAME_SELECT_OPTIONS,
                      this.props.season.seriesPageEpisodesRailDisplayName
                    )}
                    onChange={this.onSeriesPageEpisodesRailDisplayNameChange}
                    value={this.props.season.seriesPageEpisodesRailDisplayName}
                    promptTextCreator={createNewOptionPromptTextCreator}
                  />
                </div>
              </div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginBottom: '30px' }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '140px' }}>
                  <Input
                    type="checkbox"
                    label="Clips"
                    ref="seriesClipsRailEnabled"
                    groupClassName="form-inline"
                    onChange={this.onSeriesPageClipsRailEnabledChange}
                    checked={this.props.season.seriesPageClipsRailEnabled}
                  />
                </div>
                <div style={{ flexGrow: 1 }}>
                  <Creatable
                    ref="seriesPageClipsRailDisplayNameInput"
                    placeholder={SERIES_PAGE_CLIPS_RAIL_DISPLAY_NAME_DEFAULT}
                    disabled={!this.props.season.seriesPageClipsRailEnabled}
                    options={compileSelectOptions(
                      SERIES_PAGE_CLIPS_RAIL_DISPLAY_NAME_SELECT_OPTIONS,
                      this.props.season.seriesPageClipsRailDisplayName
                    )}
                    onChange={this.onSeriesPageClipsRailDisplayNameChange}
                    value={this.props.season.seriesPageClipsRailDisplayName}
                    promptTextCreator={createNewOptionPromptTextCreator}
                  />
                </div>
              </div>
            </Panel>
          )}
          <Panel header="Default Geoblocking Settings" bsStyle="primary" eventKey="1">
            <Input
              type="checkbox"
              label="Episodes Available only in Australia"
              ref="episodesGeoBlocked"
              id="check-episode-geo-blocked"
              groupClassName="form-inline"
              onChange={this.onEpisodesGeoBlockedChange}
              checked={this.props.season.episodesGeoBlocked}
            />
            <Input
              type="checkbox"
              label="Clips Available only in Australia"
              ref="clipsGeoBlocked"
              id="check-clip-geo-blocked"
              groupClassName="form-inline"
              onChange={this.onClipsGeoBlockedChange}
              checked={this.props.season.clipsGeoBlocked}
            />
          </Panel>
          <Panel header="Enable Watermark" bsStyle="primary" eventKey="1">
            <Input
              type="checkbox"
              label="Enable Watermark on Episodes"
              ref="episodesWatermarkEnabled"
              id="check-episode-watermark"
              groupClassName="form-inline"
              onChange={this.onEpisodesWatermarkEnabledChange}
              checked={this.props.season.episodesWatermarkEnabled}
            />
            <Input
              type="checkbox"
              label="Enable Watermark on Clips"
              ref="clipsWatermarkEnabled"
              id="check-clip-watermark"
              groupClassName="form-inline"
              onChange={this.onClipsWatermarkEnabledChange}
              checked={this.props.season.clipsWatermarkEnabled}
            />
          </Panel>
          <SeoMetadata onChange={this.props.updateSeason} season={this.props.season} />
          <Panel header="Ad Configuration" bsStyle="primary" eventKey="1">
            <Input
              type="checkbox"
              label="Default show advertising for episodes and clips"
              ref="defaultAdsEnabled"
              id="check-default-pre-roll"
              groupClassName="form-inline"
              onChange={this.onDefaultAdsEnabledChange}
              checked={this.props.season.defaultAdsEnabled}
            />
            <Input
              type="select"
              label="Gender Skew"
              ref="genderSkew"
              id="genderSkew"
              labelClassName="required"
              onChange={this.onGenderSkewChange}
              value={this.props.season.genderSkew}
            >
              {GENDER_GROUPS.map((genderSkew) => {
                return (
                  <option key={genderSkew.id} value={genderSkew.id}>
                    {genderSkew.name}
                  </option>
                );
              })}
            </Input>
            <Input
              type="select"
              label="Ad Content Group"
              ref="contentGroup"
              id="contentGroup"
              labelClassName="required"
              onChange={this.onContentGroupChange}
              value={this.props.season.contentGroup}
            >
              {CONTENT_GROUPS.map((group) => {
                return (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                );
              })}
            </Input>
            <MultiInputList
              ref="availableContentSeries"
              id="multi-list-available-content-series"
              validate={validateContentSeries}
              title="Available Content Series for Clips"
              items={
                this.props.season.availableContentSeries &&
                this.props.season.availableContentSeries.map((contentSeries) => ({ value: contentSeries }))
              }
              addButtonText="Add"
              emptyItemMessage="Enter content series eg. some-content-series"
              onChange={(items) => this.onAvailableContentSeriesChange(items.map(({ value }) => value))}
            />
          </Panel>
          <Panel header="Content Classification" bsStyle="primary" collapsible defaultExpanded={false}>
            <ClassificationSection
              id="season-classification-list"
              title="Classification History"
              ref="Classifications"
              classifications={this.props.season.classifications}
              seasonCreatedAt={this.props.season.createdAt}
              onChange={(classifications) => this.onClassificationsChange(classifications)}
            />
          </Panel>
          <Panel header="Default YouTube Content ID" bsStyle="primary" collapsible defaultExpanded={false}>
            <DefaultContentIdSection
              season={this.props.season}
              updateSeason={this.props.updateSeason}
              validationErrors={this.props.validationErrors}
            />
          </Panel>
          <AdditionalMetadata
            season={this.props.season}
            updateSeason={this.props.updateSeason}
            seasonSportTypes={this.state.seasonSportTypes}
          />
          <Panel header="Notes" bsStyle="primary">
            {/* This element needs to on onInput in order for the unit tests to work. */}
            <Input
              id="producer-notes-input"
              type="textarea"
              label="Producer Notes"
              ref="producerNotes"
              onInput={(e) => this.onProducerNotesInput(e)}
              value={this.props.season.producerNotes}
            />
          </Panel>
          {this.props.errorMessage && (
            <FormErrorMessage className="season-server-error-message" message={this.props.errorMessage} />
          )}
          <div className="modal-footer">
            <Button
              id="video-modal-close"
              type="button"
              ref="closeButton"
              className="form__button"
              onClick={this.props.close}
              disabled={this.isCloseDisabled()}
            >
              Close
            </Button>
            <Button
              ref="submitButton"
              id="video-modal-submit"
              type="submit"
              className="form__button"
              bsStyle="primary"
              disabled={this.isSubmitDisabled()}
              data-pw="video-modal-submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

SeasonForm.propTypes = {
  season: PropTypes.object,
  channels: PropTypes.array,
  errorMessage: PropTypes.string,
  saving: PropTypes.bool,
  landscapeImageUpload: PropTypes.object,
  squareImageUpload: PropTypes.object,
  close: PropTypes.func,
  saveSeason: PropTypes.func,
  updateSeason: PropTypes.func,
  validationErrors: PropTypes.object,
  showFormValidation: PropTypes.func,
  multiImageUpload: PropTypes.object,
};

function mapStateToProps(state) {
  const seasonForm = state.seasonForm;
  return {
    season: seasonForm.season,
    channels: seasonForm.channels,
    errorMessage: seasonForm.errorMessage,
    saving: seasonForm.saving,
    landscapeImageUpload: state.imageUploads[SEASON_IMAGE_LANDSCAPE_REF],
    squareImageUpload: state.imageUploads[SEASON_IMAGE_SQUARE_REF],
    multiImageUpload: state.multiImageUpload,
    validationErrors: state.seasonForm.validationErrors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveSeason: (season) => dispatch(saveSeason(season)),
    updateSeason: (season) => dispatch(updateSeason(season)),
    showFormValidation: () => dispatch(showFormValidation()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SeasonForm);
