import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { getCurrentSeason, resetDetailsView } from 'src/scripts/actions/season';
import ViewLoader from 'src/scripts/components/ViewLoader';
import ContextNavigation from 'src/scripts/components/ContextNavigation';
import DetailsToolbar from 'src/scripts/components/Season/DetailsView/DetailsToolbar';
import MainPanel from 'src/scripts/components/Season/DetailsView/Panels/MainPanel';
import ArtworkPanels from 'src/scripts/components/Season/DetailsView/Panels/ArtworkPanels';
import GeoblockingPanel from 'src/scripts/components/Season/DetailsView/Panels/GeoblockingPanel';
import WatermarkPanel from 'src/scripts/components/Season/DetailsView/Panels/WatermarkPanel';
import SeoMetadataPanel from 'src/scripts/components/Season/DetailsView/Panels/SeoMetadataPanel';
import AdConfigurationPanel from 'src/scripts/components/Season/DetailsView/Panels/AdConfigurationPanel';
import ContentClassificationPanel from 'src/scripts/components/Season/DetailsView/Panels/ContentClassificationPanel';
import YoutubeContentIdPanel from 'src/scripts/components/Season/DetailsView/Panels/YoutubeContentIdPanel';
import NotesPanel from 'src/scripts/components/Season/DetailsView/Panels/NotesPanel';

export class SeasonDetailsView extends React.Component {
  componentDidMount() {
    this.props.resetDetailsView();
    this.props.getSeason(this.props.params.seasonId);
  }

  componentWillUnmount() {
    this.props.resetDetailsView();
  }

  render() {
    return (
      <ViewLoader loading={this.props.loading} error={this.props.error}>
        {this.props.season ? (
          <Row>
            <ContextNavigation
              breadcrumbList={[
                <a href="/#/tv/tv-series">TV Series</a>,
                <a href={`/#/tv/tv-series/${this.props.season.partOfSeries.id}/seasons`}>
                  {this.props.season.partOfSeries.name}
                </a>,
                this.props.season.name,
              ]}
            />
            <DetailsToolbar season={this.props.season} />
            <Col md="6">
              <MainPanel season={this.props.season} />
              <GeoblockingPanel season={this.props.season} />
              <WatermarkPanel season={this.props.season} />
              <SeoMetadataPanel season={this.props.season} />
              <AdConfigurationPanel season={this.props.season} />
              <ContentClassificationPanel season={this.props.season} />
              <YoutubeContentIdPanel season={this.props.season} />
              <NotesPanel season={this.props.season} />
            </Col>
            <Col md="6">
              <ArtworkPanels season={this.props.season} />
            </Col>
          </Row>
        ) : null}
      </ViewLoader>
    );
  }
}

SeasonDetailsView.propTypes = {
  season: PropTypes.object,
  error: PropTypes.string,
  loading: PropTypes.bool,
  params: PropTypes.shape({
    id: PropTypes.string,
    seasonId: PropTypes.string,
  }),
  getSeason: PropTypes.func,
  resetDetailsView: PropTypes.func,
};

const mapStateToProps = (state) => ({
  season: state.seasonDetailsView.season,
  error: state.seasonDetailsView.error,
  loading: state.seasonDetailsView.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getSeason: (seasonId) => dispatch(getCurrentSeason(seasonId)),
  resetDetailsView: () => dispatch(resetDetailsView()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeasonDetailsView);
